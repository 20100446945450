import { RouteManager } from '../../routes/route-manager';

export function filterAllowedMenuChildren(groups, permissions) {
  const allowedGroups = [];

  for (const group of groups) {
    const allowedChildren = [];

    for (const children of group.children) {
      if (!RouteManager.hasPermission(children.path, permissions)) {
        continue;
      }

      allowedChildren.push(children);
    }

    if (allowedChildren.length === 0) {
      continue;
    }

    const allowedGroup = { ...group, children: allowedChildren };

    allowedGroups.push(allowedGroup);
  }

  return allowedGroups;
}
