import { Accordion, withStyles } from '@material-ui/core';

export default withStyles({
  root: {
    borderBottom: '1px solid rgba(255, 255, 255, .25)',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
  },
  expanded: {},
})(Accordion);
