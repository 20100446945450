import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AuthLayout from './AuthLayout/AuthLayout';
import DefaultLayout from './DefaultLayout/DefaultLayout';
import ErrorLayout from './ErrorLayout/ErrorLayout';
import WithoutLayout from './WithoutLayout/WithoutLayout';
import { FeatureFlagProvider } from '../context/feature-flags.context';

const LayoutManager = () => {
  return (
    <Switch>
      <Route path="/auth">
        <AuthLayout />
      </Route>
      <Route path="/app">
        <FeatureFlagProvider>
          <DefaultLayout />
        </FeatureFlagProvider>
      </Route>
      <Route path="/without-layout">
        <FeatureFlagProvider>
          <WithoutLayout />
        </FeatureFlagProvider>
      </Route>
      <Route path="/error">
        <ErrorLayout />
      </Route>
      <Route path="*">
        <Redirect to="/auth" />
      </Route>
    </Switch>
  );
};

export default LayoutManager;
