import * as Actions from '../actions/offers.actions';

const initialState = {
  offerLoading: false,
  pupularLoading: false,
  popularOffers: [],
  regularOffers: [],
  offer: {},
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_LOADING_OFFER: {
      return {
        ...state,
        offerLoading: action.loading,
      };
    }
    case Actions.SET_LOADING_POPULAR: {
      return {
        ...state,
        pupularLoading: action.loading,
      };
    }
    case Actions.SET_OFFER_BY_ID: {
      return {
        ...state,
        offer: { ...action.offer },
      };
    }
    case Actions.SET_POPULAR_OFFERS: {
      return {
        ...state,
        popularOffers: action.popularOffers,
      };
    }
    case Actions.SET_REGULAR_OFFERS: {
      return {
        ...state,
        regularOffers: {
          ...action.regularOffers,
          totalPages: Math.ceil(
            action.regularOffers.total / action.regularOffers.pageSize,
          ),
        },
      };
    }
    case Actions.SET_LOADING_ONBOARDING: {
      return {
        ...state,
        onboardingLoading: action.loading,
      };
    }
    case Actions.SET_ONBOARDING_OFFERS: {
      return {
        ...state,
        onboardingOffers: action.onboardingOffers,
      };
    }

    default: {
      return state;
    }
  }
};

export default handle;
