import React from 'react';
import {
  Snackbar,
  SnackbarContent,
  IconButton,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import LoginPage from '../../pages/auth/LoginPage/LoginPage';

// redux actions
import { hideSnackBar } from '../../store/actions';

import './style.scss';

class AuthLayout extends React.Component {
  theme = createTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#fbc02d',
      },
      secondary: {
        main: '#ef5350',
      },
    },
  });

  render() {
    const { hideSnackBar, snackbar } = this.props;
    return (
      <ThemeProvider theme={this.theme}>
        <CssBaseline />
        <div className="auth-layout">
          <Switch>
            <Route exact path="/auth/login">
              <LoginPage />
            </Route>
            <Route exact path="/auth">
              <Redirect to="/auth/login" />
            </Route>
          </Switch>
          <Snackbar
            open={snackbar.visible}
            autoHideDuration={6000}
            onClose={hideSnackBar}
          >
            <SnackbarContent
              message={snackbar.message}
              className={snackbar.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={hideSnackBar}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}

function mapStateToProps({ snackbar, auth }) {
  return {
    snackbar,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    hideSnackBar: () => dispatch(hideSnackBar()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AuthLayout);
