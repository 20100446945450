import React from 'react';
import { List, ListItem, ListItemText, Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import MuiAccordion from './MuiAccordion';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiAccordionDetails from './MuiAccordionDetails';

export default function MenuGroup({ group, onItemClick }) {
  return (
    <MuiAccordion square>
      <MuiAccordionSummary aria-controls="panel1a-content" id="panel1a-header">
        <Typography>{group.title}</Typography>
      </MuiAccordionSummary>
      <MuiAccordionDetails>
        <List>
          {group.children.map(el => {
            return (
              <ListItem
                button
                key={el.title}
                activeClassName="active-menu-item"
                component={NavLink}
                exact
                to={el.path}
                onClick={onItemClick}
              >
                <ListItemText primary={el.title} />
              </ListItem>
            );
          })}
        </List>
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}
