import Offers from '../../services/offers.service';
import { showSnackBar } from './snackbar.actions';

/** Actions types ***/

export const SET_POPULAR_OFFERS = '[OFFER] SET POPULAR';
export const SET_REGULAR_OFFERS = '[OFFER] SET REGULAR';
export const SET_OFFER_BY_ID = '[OFFER] SET BY ID';
export const SET_LOADING_OFFER = '[OFFER] SET LOADING';
export const SET_LOADING_POPULAR = '[OFFER] SET LOADING POPULAR';
export const SET_LOADING_REGULAR = '[OFFER] SET LOADING REGULAR';
export const SET_ONBOARDING_OFFERS = '[OFFER] SET ONBOARDING';
export const SET_LOADING_ONBOARDING = '[OFFER] SET LOADING ONBOARDING';

//--------------------------
/** Actions **/

/**
 *
 * @param {ARRAY} popularOffers
 */
export const setPopularOffers = popularOffers => {
  return {
    type: SET_POPULAR_OFFERS,
    popularOffers,
  };
};

/**
 *
 * @param {Object} regularOffers
 */
export const setRegularOffers = regularOffers => {
  return {
    type: SET_REGULAR_OFFERS,
    regularOffers,
  };
};

/**
 *
 * @param {ARRAY} onboardingOffers
 */
export const setOnboardingOffers = onboardingOffers => {
  return {
    type: SET_ONBOARDING_OFFERS,
    onboardingOffers,
  };
};

/**
 *
 * @param {Object} offer
 */
export const setOfferById = offer => {
  return {
    type: SET_OFFER_BY_ID,
    offer,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setOfferLoading = loading => {
  return {
    type: SET_LOADING_OFFER,
    loading,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setPopularLoading = loading => {
  return {
    type: SET_LOADING_POPULAR,
    loading,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setRegularLoading = loading => {
  return {
    type: SET_LOADING_REGULAR,
    loading,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setOnboardingLoading = loading => {
  return {
    type: SET_LOADING_ONBOARDING,
    loading,
  };
};

//--------------------------
/** Action creators **/

/**
 * load list of popular offers from db
 */

export const getPopulatOffers = () => {
  return dispatch => {
    dispatch(setPopularLoading(true));
    Offers.getPopular()
      .then(response => {
        const offers = [];
        response.data.forEach(el => {
          if (!!el.offer) {
            offers.push(el.offer);
          }
        });
        dispatch(setPopularOffers(offers));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load popular offers',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setPopularLoading(false));
      });
  };
};

/**
 *
 * @param {Object} filters
 */
export const getRegularOffers = filters => {
  if (!filters.query) {
    delete filters.query;
  }

  if (!filters.status) {
    delete filters.status;
  }

  if (!filters.ids) {
    delete filters.ids;
  } else {
    delete filters.query;
    delete filters.status;
  }

  return dispatch => {
    dispatch(setOfferLoading(true));
    Offers.getRegular(filters)
      .then(response => {
        const { hits, ...rest } = response.data;
        const offers = [];
        hits.forEach(el => {
          if (!!el) {
            offers.push(el);
          }
        });
        dispatch(setRegularOffers({ offers, ...rest }));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load regular offers',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setOfferLoading(false));
      });
  };
};

/**
 * @param {Array} popularOffers
 */

export const savePopularOffers = popularOffers => {
  return dispatch => {
    dispatch(setPopularLoading(true));
    Offers.savePopular(popularOffers)
      .then(response => {
        dispatch(
          showSnackBar({
            message: 'Popular offers were saved',
            type: 'success',
          }),
        );
        if (response) {
          dispatch(setPopularOffers(response.data.map(el => el.offer)));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showSnackBar({
            message: 'Could not save popular offers',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setPopularLoading(false));
      });
  };
};

export const getOfferById = offerId => {
  return dispatch => {
    dispatch(setOfferLoading(true));
    Offers.getById(offerId)
      .then(response => {
        dispatch(setOfferById(response.data));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not find an offer with entered id',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setOfferLoading(false));
      });
  };
};

export const getOnboardingOffers = () => {
  return dispatch => {
    dispatch(setOnboardingLoading(true));
    Offers.getOnboarding()
      .then(response => {
        const offers = [];
        response.data.forEach(el => {
          if (!!el) {
            offers.push(el);
          }
        });
        dispatch(setOnboardingOffers(offers));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load onboarding offers',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setOnboardingLoading(false));
      });
  };
};

/**
 * @param {Array} onboardingOffers
 */

export const saveOnboardingOfferOrder = onboardingOffers => {
  return dispatch => {
    dispatch(setOnboardingLoading(true));
    Offers.saveOnboardingOrder(onboardingOffers)
      .then(response => {
        dispatch(
          showSnackBar({
            message: 'Onboarding offers were saved',
            type: 'success',
          }),
        );
        if (response) {
          dispatch(setOnboardingOffers(response.data));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showSnackBar({
            message: 'Could not save onboarding offers',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setOnboardingLoading(false));
      });
  };
};
