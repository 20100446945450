import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFoundPage from '../../pages/errors/NotFoundPage/NotFoundPage';
import NotAuthorizedPage from '../../pages/errors/NotAuthorizedPage/NotAuthorizedPage';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';

const DefaultLayout = () => {
  const theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#fbc02d',
      },
      secondary: {
        main: '#ef5350',
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <Route exact path="/error/404">
          <NotFoundPage />
        </Route>
        <Route exact path="/error/401">
          <NotAuthorizedPage />
        </Route>
        <Route exact path="/error">
          <Redirect to="/error/404" />
        </Route>
      </Switch>
    </ThemeProvider>
  );
};

export default DefaultLayout;
