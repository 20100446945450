const accpetedLanguages = [
  { id: 1, name: 'English', code: 'en' },
  { id: 2, name: 'Arabic', code: 'ar' },
  { id: 3, name: 'French', code: 'fr' },
  { id: 4, name: 'Arabic', code: 'ar-MA' },
];
// Added to run react as standalone for development
const REACT_APP_LANGS = window.env.REACT_APP_LANGS || 'en,ar,fr,ar-MA';
const REACT_APP_DEFAULT_LANG = window.env.REACT_APP_DEFAULT_LANG || 'en';
const enabledRoutes = window.env.REACT_APP_ENABLED_ROUTES
  ? window.env.REACT_APP_ENABLED_ROUTES.split(',')
  : [];
const config = {
  IMAGE_URL: 'https://luckyimages.azurewebsites.net',
  BANNERS_UPLOAD_PATH: '%2FLucky%2FImages%2FBanners%2F',
  BANNERS_UPLOAD_PATH_List: [
    {
      name: 'Banners',
      path: '/Lucky/Images/Banners/',
    },
    {
      name: 'WCB Banners',
      path: '/Lucky/Images/WebBanners/',
    },
    {
      name: 'Banners Dev',
      path: '/LuckyDev/Banners/',
    },
    {
      name: 'Affiliate Merchant Logos ',
      path: '/Lucky/Images/Logo/Affiliate/',
    },
    {
      name: 'Affiliate Merchant Logos Dev',
      path: '/LuckyDev/Logo/Affiliate/',
    },
    {
      name: 'Merchant Logos',
      path: '/Lucky/Images/Logo/',
    },
    {
      name: 'Merchant Logos Dev',
      path: '/LuckyDev/Logo/',
    },
    {
      name: 'Affiliate Categories Logos ',
      path: '/Lucky/Images/Categories/Affiliate/',
    },
    {
      name: 'Affiliate Categories Logos Dev',
      path: '/LuckyDev/Categories/Affiliate/',
    },
    {
      name: 'Contest Page',
      path: '/Lucky/Images/Contest/',
    },
    {
      name: 'Offer Logo',
      path: '/Lucky/Images/Offers/',
    },
    {
      name: 'Offer Logo Dev',
      path: '/LuckyDev/Offers/',
    },
  ],
  ENABLED_ROUTES: {
    AFFILIATE: enabledRoutes.includes('AFFILIATE'),
    GENERAL: enabledRoutes.includes('GENERAL'),
    FINANCING: enabledRoutes.includes('FINANCING'),
    MERCHANTS: enabledRoutes.includes('MERCHANTS'),
    OFFERS: enabledRoutes.includes('OFFERS'),
    APPLICATIONS: enabledRoutes.includes('APPLICATIONS'),
    CONTRACTS: enabledRoutes.includes('CONTRACTS'),
    FEATURE_FLAGS: enabledRoutes.includes('FEATURE_FLAGS'),
    CASHOUTS: enabledRoutes.includes('CASHOUTS'),
  },

  LANGUAGES: accpetedLanguages.filter(lang => {
    const appLang = REACT_APP_LANGS.trim().split(',');
    return appLang.includes(lang.code);
  }),

  DEFAULT_LANGUAGE: REACT_APP_DEFAULT_LANG,
  APP_ID: 'CMT',
};

export default config;
