import * as Actions from '../actions/financing.actions';

const initialState = {
  loading: false,
  list: [],
  pages: 0,
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_APPLICATIONS: {
      return {
        ...state,
        list: action.applications.data,
        pages: action.applications.meta.pages,
      };
    }
    case Actions.SET_APPLICATIONS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    default: {
      return state;
    }
  }
};

export default handle;
