import axios from './axios.service';

export default class Cashout {
  /**
   * list pagenated cashout requsts
   * @param {Object} filters
   * @returns promise
   */
  static list(filters) {
    return axios.get('/api/v2/cmt-backend/payment/affiliate/cashouts', {
      params: { ...filters },
    });
  }

  /**
   * get a cashout request by id
   * @param {Number} id
   * @returns promise
   */
  static getById(id) {
    return axios.get(`/api/v2/cmt-backend/payment/affiliate/cashouts/${id}`);
  }

  /**
   * approve a cashout by its id
   * @param {number} id
   * @returns promise
   */
  static approve(id) {
    return axios.put(
      `/api/v2/cmt-backend/payment/affiliate/cashouts/${id}/approve`,
    );
  }
  /**
   * approve a cashout by its id
   * @param {number} id
   * @param {string} reason
   * @returns promise
   */
  static reject(id, reason) {
    return axios.put(
      `/api/v2/cmt-backend/payment/affiliate/cashouts/${id}/reject?reason=${reason}`,
    );
  }
}
