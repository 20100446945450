import axios from './axios.service';
export default class Auth {
  /**
   *
   * @param {String} googleToken
   */
  static login(googleToken, domain) {
    return axios.post('/api/v2/cmt-backend/auth/login', {
      googleToken,
      domain,
    });
  }
  /**
   * check if user is logged in by check if there is a token
   * and if the time since login is less than token expiry
   */
  static isLoggedIn() {
    let isLoggedIn = true;
    const authUser = localStorage.getItem('user');
    const lastLogin = localStorage.getItem('lastLogin');
    isLoggedIn = !!authUser && !!lastLogin;

    if (!!authUser && !!lastLogin) {
      const d = Math.round(new Date().getTime() / 1000);
      isLoggedIn =
        isLoggedIn && d - parseInt(lastLogin) < JSON.parse(authUser).expiresIn;
    }
    return isLoggedIn;
  }

  /**
   * clear sotrage to remove any data related to the current user
   */
  static logout() {
    localStorage.clear();
    window.location.href = '/auth/login';
  }

  /**
   * get the access token data local storage
   * @param {String} field
   * @returns field info or null
   */
  static getTokenData(field = 'accessToken') {
    const authUser = localStorage.getItem('user');
    // if nothing in local storage then return null
    if (authUser == null) {
      return null;
    }
    const authUserObj = JSON.parse(authUser);

    // if field does not exist return null
    if (!authUserObj[field]) {
      return null;
    }
    return authUserObj[field];
  }
  /**
   * store auth data in local storage
   * @param {Object} data
   */
  static storeTokenData(data) {
    localStorage.setItem('user', JSON.stringify(data));
    localStorage.setItem('lastLogin', Math.round(new Date().getTime() / 1000));
  }

  static getPermissions() {
    const permissions = [];
    const authUser = localStorage.getItem('user');
    if (authUser) {
      const user = JSON.parse(authUser)['accessToken'].split('.')[1];

      try {
        const userData = JSON.parse(atob(user));

        if (userData.permissions == null) {
          Auth.cleanLocalStorage();
          return [];
        }

        return userData.permissions;
      } catch (e) {
        Auth.cleanLocalStorage();
      }
    }

    return permissions;
  }

  static hasPermissions(permissions) {
    const authPermissions = this.getPermissions();

    return permissions.every(item =>
      authPermissions.some(list => list.includes(item)),
    );
  }

  static cleanLocalStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('lastLogin');
    localStorage.removeItem('email');
  }
  /**
   * store user email
   * @param {String} email
   */
  static storeUserEmail(email) {
    localStorage.setItem('email', JSON.stringify(email));
  }
}
