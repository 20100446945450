import React from 'react';
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './style.scss';

export default function CMTAppBar({
  className = '',
  onLogoutClick,
  onHamburgerClick,
}) {
  return (
    <AppBar className={`${className} cmt-app-bar`}>
      <Toolbar>
        <IconButton
          className={'cmt-app-bar-hamburger'}
          component="label"
          onClick={onHamburgerClick}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" className={`cmt-app-bar-content`}>
          Content Manager - {window.env.REACT_APP_NAME.toUpperCase()}
        </Typography>
        <Button
          color="inherit"
          className={'cmt-app-bar-logout'}
          onClick={onLogoutClick}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
}
