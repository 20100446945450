import React from 'react';
import { MenuItems } from './constants';
import MenuGroup from './MenuGroup';
import { filterAllowedMenuChildren } from './utils';

export default function CMTMenu({ userPermissions, onItemClick }) {
  const filteredItems = filterAllowedMenuChildren(MenuItems, userPermissions);

  return (
    <>
      {filteredItems.map(el => (
        <MenuGroup group={el} key={el.title} onItemClick={onItemClick} />
      ))}
    </>
  );
}
