import * as Actions from '../actions/banners.actions';

const initialState = {
  loading: false,
  list: [],
  current: {},
  loadingCurrent: false,
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_BANNERS: {
      return {
        ...state,
        list: action.banners,
      };
    }
    case Actions.SET_CURRENT_BANNER: {
      return {
        ...state,
        current: action.banner,
      };
    }
    case Actions.SET_BANNERS_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    case Actions.SET_CURRENT_BANNER_LOADING: {
      return {
        ...state,
        loadingCurrent: action.loading,
      };
    }
    default: {
      return state;
    }
  }
};

export default handle;
