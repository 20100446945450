import React, { useEffect, useState } from 'react';
import './styles-connect-default.css';
import { useLocation } from 'react-router';
import ContractsService from '../../../services/constracts.service';
import { IdentityGender } from '../../../helpers/constants';

const ConnectContractDefault = props => {
  const location = useLocation();
  const { application } = location.state;
  const dateOfBirth = new Date(application.birthdate);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(
    function () {
      if (isLoading) {
        return;
      }

      ContractsService.auditCreditContract(application.userId);
      window.print();
    },
    [isLoading],
  );

  const handleImageLoad = () => {
    console.info('image loaded');
    setIsLoading(false);
  };

  return (
    <>
      <main style={{ background: 'white' }}>
        <div className="ConnectDefault__contract-body" dir="rtl">
          <div
            style={{
              position: 'relative',
              width: '100%',
              height: '30px',
              display: 'flex',
            }}
          >
            <img
              src="/assets/images/connect-logo-black.png"
              alt="logo"
              width={120}
              height={30}
              onLoad={handleImageLoad}
            />
          </div>
          <h1
            className="ConnectDefault__h1"
            style={{ textAlign: 'center', float: 'none', marginLeft: 0 }}
          >
            طلب اصدار بطاقة مدفوعة مقدما مطبوعة للعملاء
          </h1>
          <div className="ConnectDefault__title-right">
            <b>
              <u>البیانات الشخصیة:</u>
            </b>
          </div>
          <div className="ConnectDefault__box-border">
            <p>
              <b>الإسم بالكامل ( كما في بطاقة الرقم القومي)</b>
              <span
                className="ConnectDefault__underlineDotted"
                style={{ width: '300px' }}
              >
                {application.firstName} {application.secondName}{' '}
                {application.thirdName} {application.familyName}
              </span>
            </p>
            <p>
              <b>الرقم القومى:</b>
              {application.documentNumber ? (
                application.documentNumber
                  .split('')
                  .reverse()
                  .map((item, index) => {
                    return (
                      <span className="ConnectDefault__checkbox" key={index}>
                        {item}
                      </span>
                    );
                  })
              ) : (
                <>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                  <span className="ConnectDefault__checkbox"></span>
                </>
              )}
            </p>
            <p>
              <span style={{ float: 'right' }}>
                <b>تاریخ المیلاد:</b>
                <b>یوم</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '30px' }}
                >
                  {dateOfBirth.getUTCDate()}
                </span>
                <b>شھر</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '30px' }}
                >
                  {dateOfBirth.getUTCMonth() + 1}
                </span>
                <b>سنة</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '30px' }}
                >
                  {dateOfBirth.getUTCFullYear()}
                </span>
              </span>
              <span style={{ float: 'right', marginRight: '50px' }}>
                <b>تاریخ انتھاء صلاحیة البطاقة: یوم</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '30px' }}
                ></span>

                <b>شھر</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '30px' }}
                ></span>

                <b>سنة</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '30px' }}
                ></span>
              </span>
            </p>
            <br />
            <br />
            <p>
              <span style={{ float: 'right' }}>
                <b>محل الميلاد:</b>
                <span
                  className="ConnectDefault__underlineDotted"
                  style={{ minWidth: '100px' }}
                >
                  {application.placeOfBirth}
                </span>
              </span>
              <span
                style={{
                  float: 'right',
                  marginRight: '30px',
                  marginTop: '-5px',
                }}
              >
                <b>النوع: ذكر</b>
                <span
                  className={`ConnectDefault__checkbox ${
                    application.gender === IdentityGender.MALE ? 'cross' : ''
                  }`}
                ></span>
                <b>أنثى</b>
                <span
                  className={`ConnectDefault__checkbox ${
                    application.gender === IdentityGender.FEMALE ? 'cross' : ''
                  }`}
                ></span>
              </span>
            </p>
            <br />
            <br />
            <p>
              <b>عنوان محل الإقامة الدائم</b>
              <span
                className="ConnectDefault__underlineDotted"
                style={{ minWidth: '400px' }}
              >
                {application.address}
              </span>
            </p>

            <p>
              <b>رقم المحمول:</b>
              <span
                className="ConnectDefault__underlineDotted"
                style={{ minWidth: '300px' }}
              >
                {application.phone}
              </span>
            </p>
          </div>

          <div className="ConnectDefault__box-border">
            <p>
              <b>نوع البطاقة: فیزا</b>
              <span className={`ConnectDefault__checkbox`}></span>
              <b>میزا</b>
              <span className={`ConnectDefault__checkbox`}></span>
              <b>ماستر كارد</b>
              <span className={`ConnectDefault__checkbox`}></span>
            </p>
          </div>

          <div className="ConnectDefault__title-right">
            <b>
              <u>بیانات العمل</u>
            </b>
          </div>

          <div className="ConnectDefault__box-border">
            <p>
              <b>المھنة / الوظیفة و جھة العمل</b>
              <span className="ConnectDefault__underlineDotted">
                {application.profession}
              </span>
            </p>
            <p>
              <b>نوع النشاط إذا كان صاحب جھة عمل</b>
              <span
                className="ConnectDefault__underlineDotted"
                style={{ minWidth: '300px' }}
              ></span>
            </p>
            <p>
              <b>عنوان العمل بالتفصیل:</b>
              <span
                className="ConnectDefault__underlineDotted"
                style={{ minWidth: '300px' }}
              ></span>
            </p>
          </div>

          <div
            className="ConnectDefault__box-border"
            style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
          >
            <p>
              <b>ھل ل ھعلاق أب فراد ذوي صلات سیاسیة: نعم</b>
              <span className="ConnectDefault__checkbox"></span>
              <b>لا</b> <span className="ConnectDefault__checkbox"></span>
            </p>
            <p>
              <b>ھل ینطبق علیك التعریف المذكور أدناه: نعم</b>
              <span className="ConnectDefault__checkbox"></span>
              <b>لا</b> <span className="ConnectDefault__checkbox"></span>
            </p>
            <p style={{ maxWidth: '500px' }}>
              <b>
                الأشخاص المنسوب إلیھم مناصب عامھ أو فى دولة أجنبیة أو سبق أن
                أسند الیھم ھذه المناصب مثل رؤساء الدول أو رفیعة محلیا الحكومات و
                كبار السیاسین والمسؤولین العسكریین و المسؤولین فى الجھات
                القضائیة وكبار المسؤولین التنفیذیین بالشركات المملوكة للدولة و
                المسؤولین البارزین بالأحزاب السیاسیة
              </b>
            </p>
          </div>

          <div className="ConnectDefault__box-border">
            <p style={{ marginBottom: '8px' }}>
              <b>
                أقر انى المستفید الحقیقى من ھذه اقر أنا الموقع أدناه بصحة
                البیانات المدونة عالیھ وأتعھد بإبلاغكم بأیة تغییرات قد تطرأ
                علیھا فور حدوثھا واقر بصحة العنوان المذكور في ھذا الطلب وكذا
                عنوان البرید الإلكتروني ما لم اخطر البنك بما یفید تغییر ھذا
                العنوان فور ًلا على صحة توجیھ ھذه حدوث التغییر بموجب خطاب موصى
                علیة بعلم الوصول وتعتبر دفاتر البنك وسائر الأوراق والوسائل دلی
                المراسلات وإثباتا لمحتواھا ومنتجة لكافة أثارھا القانونیة ، كما
                أفوض بنك مصر بالقیام بأیة استعلامات عنى وأصرح لھ بالكشف عن كل أو
                بعض البیانات الخاصة بمعاملاتي بالبطاقة لاستیداء حقوق البنك أو
                لإثبات حقھ في أي نزاع ینشا بیني وبین البنك وذلك دون التقید
                بأحكام الفصل التاسع (سریة الحسابات) من القانون رقم 194 لسنة 2020
                بإصدار قانون البنك المركزي والجھاز المصرفي ، كما اقر ".بمسئولیتي
                عن كافة التعاملات المالیة التي تتم باستخدام البطاقة كما ھو موضح
                بشروط وأحكام استخداماتھا
              </b>
            </p>
            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <p style={{ display: 'flex' }}>
                <b style={{ flexShrink: 0 }}>توقیع مستخدم الخدمة</b>
                <span className="ConnectDefault__underlineDotted" />
              </p>
              <p style={{ display: 'flex' }}>
                <b style={{ flexShrink: 0 }}>توقیع الموظف مقدم الخدمة</b>
                <span className="ConnectDefault__underlineDotted" />
              </p>
            </div>

            <div style={{ display: 'flex', marginBottom: '8px' }}>
              <p style={{ display: 'flex' }}>
                <b style={{ flexShrink: 0 }}>التاریخ:</b>
                <span className="ConnectDefault__underlineDotted" />
              </p>
              <p style={{ display: 'flex' }}>
                <b style={{ flexShrink: 0 }}>التاریخ:</b>
                <span className="ConnectDefault__underlineDotted" />
              </p>
            </div>
            <p>
              <b>
                تم مراجعة الطلب بمعرفتي و تم التأكد من كافة البیانات المسجلة
                بالطلب ومطابقتھا بالبیانات التي تم تسجیلھا على النظام
              </b>
            </p>
            <p style={{ display: 'flex' }}>
              <b style={{ flexShrink: 0 }}>توقیع الموظف المراجع</b>
              <span
                className="ConnectDefault__underlineDotted"
                style={{ width: '100%' }}
              />
            </p>
          </div>
          <div className="ConnectDefault__title-right">
            <b>
              <u>شروط وأحكام عامة:</u>
            </b>
          </div>
          <p>
            <ul style={{ marginRight: '12px', marginBottom: '24px' }}>
              <li>
                <b style={{ textDecoration: 'underline' }}>تمهيد:</b>
                يتضمن هذا الطلب شروط وأحكام استخدام خدمة الدفع عن البطاقات
                المدفوعة مقدما، والتي بموجبها يستطيع المستخدم فتح حساب بطاقة
                مدفوعة مقدما لدي البنك للتعامل عليها سواء باإليداع، السحب أو
                التحويل اإللكتروني لألموال، ويودع بالحساب رصيد إلكتروني )وحدات
                النقود اإللكترونية( بمقدار ما أودعه المستخدم في حساب البطاقة لدي
                البنك ومن ثم يتعين علي المستخدم قراءة كافة شروط وأحكام استخدام
                الخدمة بدقة وعناية قبل التوقيع
              </li>
              <li>
                فور توقيع المستخدم على طلب االشت ارك في الخدمة وشريطة استيفائه
                باقي الشروط األخرى، واستكمال إجراءات فتح البطاقة المدفوعة مقدما
                وبعد التحقق من هويته من قبل الشركة، سيتم تفعيل خدمة الدفع عن
                طريق البطاقة المدفوعة مقدما من خالل منح المستخدم البطاقة الخاصة
                به الصادرة من شركة ميزة/ فيزا / ماستر كارد و يتم تسليم المستخدم
                الMailer PIN الخاص به )و رابط لتحميل التطبيق على متجر التطبيقات
                مع إجراءات التسجيل المطلوب القيام بها من قبل المستخدم( عند
                التسجيل بالخدمة وعلى المستخدم القيام بعملية التسجيل مرة أخرى من
                خالل التطبيق في حالة تغيير المستخدم لهاتفه المحمول و يحق للعميل
                أن يتعامل على رصيده اإللكتروني من خالل إيداع أو سحب مبالغ من
                كافة ماكينات الصراف اآللي الخاصة بالبنك ، وأيضا الشراء من خالل
                نقاط البيع االلكترونية و الشراء عبر اإلنترنت، وذلك في ضوء
                المحددات الموضوعة من قبل البنك و البنك المركزي في هذا الشأن.
              </li>
              <li>
                - يحق لمستخدم الخدمة في أي وقت إغالق حساب البطاقة المدفوعة مقدما
                و استرداد رصيد النقدية التي تقابل رصيده اإللكتروني الدائن وذلك
                بتوجه مستخدم الخدمة إلى احد فروع الشركة لسحب كامل رصيده المتبقي
                و تتم عملية اإلغالق ووقف الخدمة في خالل 15 يوم عمل من استالم طلب
                األغالق مع مسح كافة بيانات المستخدم تباعا او احتفاظ المستخدم
                بحقة في عدم اطالع الشركة على بياناته عند طلبه اغالق حسابه، وال
                يحق لمستخدم الخدمة بمطالبة البنك أو الشركة أو الرجوع عليها بوجود
                رصيد متبقى بعد مرور القترة المذكورة و ذلك دون ادنى مسئولية على
                البنك أو الشركة.
              </li>
              <li>
                يقتصر الحق في استخدام الخدمة أو االنتفاع بها على المستخدم وحده
                دون غيره، ويلتزم المستخدم في جميع األوقات بعدم السماح أو تمكين
                أي شخص أخر من استخدامها أو االنتفاع بها.
              </li>
              <li>
                - يحق للبنك و/أو للشركة وفقا لمطلق تقديره إيقاف الخدمة عن
                المستخدم أو إلغاؤها وذلك في حالة استخدامه للخدمة بالمخالفة ألي
                من شروط وأحكام خدمة الدفع عبر البطاقات المدفوعة مقدما أو
                بالمخالفة ألي من القوانين واللوائح الواجبة التطبيق.
              </li>
              <li>
                يحق للبنك تقييد أو إغالق حساب المستخدم في أي وقت حسب االقتضاء
                إذا كان ذلك مطلوبا بموجب القانون أو االتفاقيات الدولية أو إذا
                كان لدى البنك أسباب معقولة أو مؤيدة الحتيال المستخدم أو استخدامه
                الحساب لغرض غير مشروع أو غير الئق أو بما يخالف القوانين السارية
                أو بما يخالف شروط وأحكام الحسابات المصرفية المطبقة بالبنك، كما
                يجوز للبنك أيضا تجميد أو إغالق الحساب في حالة كون المستخدم ضحية
                لالحتيال أو سرقة الهوية من أجل تفادي الخسائر المحتملة
              </li>
              <li>
                يحق للبنك أن يجمد أو يوقف تشغيل الحساب أو يغلق الحساب وأن يرفض
                أي من المعامالت المصرفية إذا كان صاحب الحساب مخالً بأي من شروط
                وأحكام هذا الطلب أو إذا كانت قدمت للبنك أي تعليمات غامضة أو
                متناقضة أو غير مقبولة من البنك أو إذا شك البنك في وجود حاالت غش
                أو عدم قانونية أو مشروعية في أي معامالت ، كما يجوز للبنك أن يبلغ
                عن أي عمليات غسل أموال أو معامالت مشكوك فيها أو غير قانونية في
                الحساب إلى السلطات الرقابية بجمهورية مصر العربية أو بالخارج حيث
                يلتزم البنك بجميع قوانين مكافحة غسل األموال ونظمها السارية ويجوز
                له و فقا لتقديره الخاص أن يطلب تفاصيل أو معلومات إضافية من صاحب
                الحساب وأن يقدم تلك المعلومات والتفاصيل إلى السلطات المعنية وفي
                تلك الحاالت لن يتحمل البنك أي مسؤولية أو التزام عن تأخير تنفيذ
                أي معامالت إذا لم تقدم المعلومات المطلوبة من قبل صاحب الحساب في
                الموعد المطلوب.
              </li>
              <li>
                يلتزم المستخدم بالمحافظة على رقمه السري وإبقائه في جميع األوقات
                تحت مسئوليته الشخصية، وتبعا لذلك سوف يكون المستخدم وحده مسئوال
                عن إفشاء أي من البيانات أو المعلومات المتعلقة به أو بأي من
                حساباته إذا كان ذلك اإلفشاء ناشئا عن خطأ أو تقصير أو إهمال في
                المحافظة على رقمه السري الخاص به دون أدني مسئولية على البنك و/أو
                الشركة.
              </li>
              <li>
                - يقر المستخدم بمسؤوليته عن جميع المعامالت وكافة العمليات التي
                تتم على حساب البطاقة المرتبطة بالخدمة وباستخدام رقمه السري وسوف
                تكون دفاتر البنك وسجالته حجة على المستخدم والغير.
              </li>
              <li>
                لن يكون البنك و/أو الشركة مسئوال عن أية خسائر أو أض ارر قد تحدث
                للمستخدم بسبب سوء أو إساءة استخدامه للخدمة أو نتيجة ألي خطأ
                يرتكبه أو نتيجة أي خلل قد يحدث للخدمة بسبب خطأ من جانبه أو لعدم
                كفاية رصيد حساب البطاقة أو ألي سبب آخر قد يكون للمستخدم دخل فيه
                ولن يكون البنك و/أو الشركة مسئوال عن أي خلل في شبكات االتصاالت
                ويقر بعدم مسئولية البنك و/أو الشركة أو تحمله أية تكاليف أو أعباء
                قد تحدث نتيجة انقطاع الخدمة بشبكة االنترنت أو من جراء أية مشاكل
                أو اعطال قد تطرأ على الشبكة.
              </li>
              <li>
                لن يكون البنك و/أو الشركة مسئوال عن رفض أي مؤسسة قبول الرصيد
                اإللكتروني كوسيلة سداد كما أنه لن يكون مسئوال عن البضائع أو
                الخدمات التي سوف يحصل عليها المستخدم في مقابل ذلك الرصيد، وأى
                شكوى من المستخدم في هذا الصدد يجب عليه حلها مع تلك المؤسسة
                مباشرة وسوف يقتصر دور البنك في هذا الخصوص على إضافة أي مبلغ قد
                يسترده المستخدم إلى حساب البطاقة وذلك بعد حصول البنك على سند
                إضافة صادر بشكل صحيح من تلك المؤسسة.
              </li>
              <li>
                يتعين على المستخدم ابالغ الشركة في حالة سرقة البطاقة او الهاتف
                المحمول أو فقد احد منهم و في جميع االحوال في حالة قيام شخص اخر
                بإجراء أي تعامالت على حسابات المستخدم من خالل تلك الخدمة و قبل
                قيام المستخدم بإبالغ الشركة يكون المستخدم مسئوال مسئولية كاملة
                تجاه البنك و/أو الشركة كافة النتائج المترتبة على تلك المعامالت
                ويقوم المستخدم باإلبالغ عن طريق االتصال بخدمة العمالء الخاصة
                بالشركة لوقف الخدمة و في حالة رغبة المستخدم اعادة تفعيل الخدمة
                يجب عليه االتصال بخدمة العمالء الخاصة بالشركة.
              </li>
              <li>
                يحق للبنك وضع حدود قصوى لحجم رصيد البطاقة وقيمة و عدد كل من
                العمليات اليومية والشهرية ، كما يحق للبنك تغيرها من وقت آلخر مع
                اخطار المستخدم باى وسيلة مناسبة يراها البنك و يعتبر استخدام
                المستخدم للخدمة بعد اسة البنك ووفقا فقط وبالعملة المحلية )الجنيه
                المصري( ً تغير تلك الحدود بمثابة موافقة منه ويتم ذلك في ضوء سي
                للقواعد المنظمة لهذه الخدمة والصادرة من البنك المركزي المصري و
                تتم عمليات التحويل داخل جمهورية مصر العربية فقط وال يسمح بتبادل
                عمالت أخري أو إج ارء عمليات تبادل للعمالت أو مقاصة بين حسابات
                المستخدمين ذات العمالت األخرى و يسمح لمستخدمي الخدمة بتلقي
                تحويالت من الخارج بالعمالت األجنبية ويتم إضافتها إلي حسابه
                بالجنيه المصري، علي أن يكون المستخدم قد خضع إلج ارءات التعرف علي
                الهوية بموجب قواعد التعرف علي الهوية الصادرة عن وحدة غسل األموال
                وتمويل اإلرهاب التابعة للبنك المركزي المصري.
              </li>
              <li>
                يحق للبنك رفض تنفيذ بعض العمليات في أي من الحاالت التي يراها
                البنك غير مناسبة كما يحق له عدم االفصاح عن اسباب ويتخذ البنك
                اإلجراءات المناسبة للتأكد من أن التحويل يخص ذات المستخدم وأن
                القيمة قد تم إضافتها حماية الرقم السري الخاص به واإلبالغ فو ارً
                بالجنيه المصري لحساب البطاقة الخاصة به وعلى المستخدم عند فقد
                بطاقته.
              </li>
              <li>
                في حالة وجود شكوى او ن ازع مالى نتيجة استخدام الخدمة من قبل
                المستخدم فليتوجه ألقرب فرع من فروع الشركة والتقدم بالشكوى او الن
                ازع موقعا عليها أو من خالل االتصال بأرقام خدمة العمالء الخاصة
                بالشركة أوالخط الساخن _______ وسيتم الرد عليه خالل خمسة عشر يوم
                )15( عمل من تاريخ تقديم الشكوى عدا الشكاوى المتعلقة بمعامالت
                الخارجية ويتم اخطار المستخدم بالمدة الالزمة لد ارسة الشكوى.
              </li>
            </ul>
          </p>

          <div className="ConnectDefault__title-right">
            <b>
              <u>تولى المناصب العامة العليا أو االرتباط بمن يتوالها:</u>
            </b>
          </div>

          <p>
            <ul style={{ marginRight: '12px' }}>
              <li>
                وفقا للتعريف، هم االشخاص الذين يشغلون مناصب عامة عليا فى مصر او
                في دولة اجنبية أو منظمة دولية سواء حاليا أو سابقا، و على سبيل
                المثال ال الحصر:
              </li>
              <li>
                رؤساء الدول أو الحكومات و أفراد العائالت المالكة و الحاكمة بأى
                دولة و الوزراء و نوابهم و كبار مساعديهم، رؤساء و أعضاء المجالس
                النيابية و المجالس المحلية و البلديات، المحافظين ونوابهم و رؤساء
                المدن ونوابهم و العمد ونوابهم ورؤساء و أعضاء
              </li>
              <li>
                المجالس المتخصصة( مثل :المجلس القومى للمرأة، المجلس القومى لحقوق
                االنسان )وكبار السياسيين، وكبار مسئولي الحكومة والمسئولين
                العسكريين والمسئولين الرئيسسيين في الجهات القضائية( على سبيل
                المثال :رئيس المحكمة الدستورية و نوابه، رئيس المجلس األعلى
                للقضاء ونوابه، رئيس محكمة النقض و نوابه، رئيس محكمة االستئناف و
                نوابه، النائب العام و كبار مساعديه)، ورؤساء الشركات المملوكة
                للدولة و نوابهم و كبار مساعديهم، و رؤساء و أعضاء الهيئات العليا
                باألحزاب السياسية و كبار الدبلوماسيين و المنظمات الدبلوماسية
                الدولية( على سبيل المثال :األمم المتحدة، اليونسكو، الخ)، رؤساء و
                أعضاء مجالس ادارة النقابات و رؤساء و أعضاء مجالس الجامعات
                الحكومية.
              </li>
            </ul>
          </p>
        </div>
      </main>
    </>
  );
};

export default ConnectContractDefault;
