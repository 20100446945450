import * as Actions from '../actions/cashouts.actions';

const initialState = {
  paginatedCashouts: {
    items: [],
    totalCount: 0,
  },
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_CASHOUTS_LIST: {
      return {
        ...state,
        paginatedCashouts: action.paginatedCashouts,
      };
    }

    default: {
      return state;
    }
  }
};

export default handle;
