import * as Actions from '../actions/file.actions';

const initialState = {
  uploading: false,
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_FILE_UPLOADING: {
      return {
        ...state,
        uploading: action.uploading,
      };
    }
    default: {
      return state;
    }
  }
};

export default handle;
