import Auth from '../../services/auth.service';
import { showSnackBar } from './snackbar.actions';
import history from '../../history';
/** Actions types ***/

export const SET_AUTH_USER = '[AUTH] SET USER';
export const SET_IS_AUTHENTICATED = '[AUTH] SET IS AUTHENTICATED';

//--------------------------
/** Actions **/

/**
 *
 * @param {Object} user
 */
export const setAuthUser = user => {
  return {
    type: SET_AUTH_USER,
    user,
  };
};

/**
 *
 * @param {Boolean} isLoggedIn
 */
export const setIsAuthenticated = isLoggedIn => {
  return {
    type: SET_IS_AUTHENTICATED,
    isLoggedIn,
  };
};

//--------------------------
/** Action creators **/

/**
 *
 * @param {String} googleToken
 */
export const login = (googleToken, domain) => {
  return dispatch => {
    dispatch(setIsAuthenticated(true));
    Auth.login(googleToken, domain)
      .then(response => {
        dispatch(setAuthUser(response.data));
        Auth.storeTokenData(response.data);
        const user = JSON.parse(atob(response.data.accessToken.split('.')[1]));
        Auth.storeUserEmail(user.email);
        history.push('/app');
      })
      .catch(error => {
        console.error(
          '[auth.actions.js] Error has occurred when tying to login',
          error,
        );
        dispatch(
          showSnackBar({
            message: 'Incorrect username / password',
            type: 'error',
          }),
        );
      });
  };
};

export const login2FA = (otp, token) => {
  return dispatch => {
    dispatch(setIsAuthenticated(true));
    Auth.validateOTP(otp, token)
      .then(response => {
        dispatch(setAuthUser(response.data));
        Auth.storeTokenData(response.data);
        history.push('/app');
      })
      .catch(error => {
        console.error(
          '[auth.actions.js] Error has occurred when tying to login with 2FA',
          error,
        );
        dispatch(
          showSnackBar({
            message: 'Incorrect OTP',
            type: 'error',
          }),
        );
      });
  };
};
/**
 *
 */
export const logout = () => {
  return dispatch => {
    Auth.logout();
    dispatch(setAuthUser(null));
    dispatch(setIsAuthenticated(false));
    // reload the page to make sure that local state was cleared
    window.location.href = '/auth/login';
  };
};

/**
 *
 */
export const isAuthenticated = () => {
  return dispatch => {
    dispatch(setIsAuthenticated(Auth.isLoggedIn()));
    dispatch(setAuthUser(JSON.parse(localStorage.getItem('user'))));
  };
};
