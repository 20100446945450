/** Actions types ***/

export const SNACKBAR_SHOW = '[SNACKBAR] SHOW';
export const SNACKBAR_HIDE = '[SNACKBAR] HIDE';

//--------------------------
/** Actions **/

/**
 *
 * @param {Object} options
 */
export const showSnackBar = options => {
  return {
    type: SNACKBAR_SHOW,
    options,
  };
};

export const hideSnackBar = () => {
  return {
    type: SNACKBAR_HIDE,
  };
};
