import * as Actions from '../actions/auth.actions';

const initialState = {
  user: null,
  isAuthenticared: false,
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_AUTH_USER: {
      return {
        ...state,
        user: action.user,
      };
    }
    case Actions.SET_IS_AUTHENTICATED: {
      return {
        ...state,
        isAuthenticared: action.isLoggedIn,
      };
    }
    default: {
      return state;
    }
  }
};

export default handle;
