import React, { createContext, useContext, useEffect, useState } from 'react';
import Configs from '../services/configs.service';

export const FeatureFlagContext = createContext({});

export const FEATURE_FLAGS = {
  isABMLuckyOneCardsOffersEnabled: 'isABMLuckyOneCardsOffersEnabled',
  isCardCaasEnabled: 'isCardCaasEnabled',
  caasOrganizations: 'caasOrganizations',
};

export function FeatureFlagProvider(props) {
  const [featureFlags, setFeatureFlags] = useState(new Map());
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);

    Configs.getFeatureFlags().then(response => {
      const map = new Map();

      response.data?.forEach(featureFlag =>
        map.set(featureFlag.key, featureFlag.value),
      );

      setFeatureFlags(map);
      setIsLoading(false);
    });
  }, []);

  return (
    !isLoading && (
      <FeatureFlagContext.Provider value={{ featureFlags }}>
        {props.children}
      </FeatureFlagContext.Provider>
    )
  );
}
export const useFeatureFlagContext = () => useContext(FeatureFlagContext);
