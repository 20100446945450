import Categories from '../../services/categories.service';
import { showSnackBar } from './snackbar.actions';

/** Actions types ***/
export const SET_CATEGORIES_LIST = '[CATEGORIES] SET LIST';

//--------------------------
/** Actions **/

/**
 *
 * @param {Obkect} paginatedCategories
 */
export const setCategoriesList = paginatedCategories => {
  return {
    type: SET_CATEGORIES_LIST,
    paginatedCategories,
  };
};

//--------------------------
/** Action creators **/

/**
 *
 * @param {*} filters
 * @returns
 */
export const getCategoriesList = filters => {
  return dispatch => {
    Categories.listCategories(filters)
      .then(response => {
        dispatch(setCategoriesList({ ...response.data }));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load the list of categories',
            type: 'error',
          }),
        );
      });
  };
};
