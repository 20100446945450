import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import store from './store';
import history from './history';
import LayoutManager from './layouts/LayoutManager';
import './App.scss';

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <LayoutManager />
      </Router>
    </Provider>
  );
}

export default App;
