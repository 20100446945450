import React from 'react';
import { GoogleLogin } from '@react-oauth/google';

import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../../../history';

import Auth from '../../../services/auth.service';

import {
  isAuthenticated,
  login,
  setAuthUser,
  setIsAuthenticated,
} from '../../../store/actions';

import './style.scss';

class LoginPage extends React.Component {
  state = {
    email: '',
    password: '',
  };

  loginAction = (googleToken, domain) => {
    const { login } = this.props;
    login(googleToken, domain);
  };

  componentDidMount() {
    // check if user is still logged in
    if (Auth.isLoggedIn()) {
      const { isAuthenticated } = this.props;
      isAuthenticated();
      history.push('/app');
    }
  }

  render() {
    return (
      <div className="login-page">
        <Container className="full-page">
          <Box
            className="full-page"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Card className="auth-card">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography className="text-center" gutterBottom>
                      Sign in ({window.env.REACT_APP_NAME.toUpperCase()})
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className={'buttons-container'}>
                    <Grid item xs={12}>
                      Login with "thelucky.app" email
                    </Grid>
                    <Grid item xs={12}>
                      <GoogleOAuthProvider
                        clientId={
                          window.env.REACT_APP_GOOGLE_INTERNAL_CLIENT_ID
                        }
                      >
                        <GoogleLogin
                          clientId={
                            window.env.REACT_APP_GOOGLE_INTERNAL_CLIENT_ID
                          }
                          onSuccess={res => {
                            this.loginAction(res.credential, 'thelucky.app');
                          }}
                          onError={() => {
                            console.log('Google login failed');
                          }}
                        />
                      </GoogleOAuthProvider>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} className={'buttons-container'}>
                    <Grid item xs={12}>
                      Login with "connect.money" email
                    </Grid>
                    <Grid item xs={12}>
                      <GoogleOAuthProvider
                        clientId={
                          window.env.REACT_APP_CAAS_GOOGLE_INTERNAL_CLIENT_ID
                        }
                      >
                        <GoogleLogin
                          style={{ background: 'red' }}
                          clientId={
                            window.env.REACT_APP_CAAS_GOOGLE_INTERNAL_CLIENT_ID
                          }
                          onSuccess={res => {
                            this.loginAction(res.credential, 'connect.money');
                          }}
                          onError={() => {
                            console.log('Google login failed');
                          }}
                        />
                      </GoogleOAuthProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Box>
        </Container>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    setAuthUser: user => dispatch(setAuthUser(user)),
    setIsAuthenticated: isLoggedin => dispatch(setIsAuthenticated(isLoggedin)),

    ...bindActionCreators(
      {
        login: login,
        isAuthenticated: isAuthenticated,
      },
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
