import { Routes } from './constants';
import { matchPath } from 'react-router-dom';

class RouteManager {
  static getFirstAllowedRoute(userPermissions) {
    if (!userPermissions) {
      return Routes[0].path;
    }

    const [firstAllowedRoute] = Routes.filter(route =>
      userPermissions.includes(route.permission),
    );

    return firstAllowedRoute?.path || Routes[0].path;
  }

  static hasPermission(path, userPermissions) {
    if (!userPermissions) {
      return false;
    }

    const route = Routes.find(route => {
      const match = matchPath(path, route.path);

      return match != null;
    });

    if (!route.permission) {
      // If permission was not specified, let's block the user to access
      console.warn(`Permission for route ${route.path} was not specified.`);
      return false;
    }

    return userPermissions.includes(route.permission);
  }
}

export { RouteManager };
