import React from 'react';
import { RouteManager } from '../../routes/route-manager';
import { Redirect } from 'react-router-dom';

export default function ProtectedRoute({ children, path, userPermissions }) {
  const userHasPermission = RouteManager.hasPermission(path, userPermissions);

  if (!userHasPermission) {
    return <Redirect to="/error/401" />;
  }

  return children;
}
