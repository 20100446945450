import { Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  return (
    <Grid container spacing={3} justify="center">
      <Grid item xs={8} className="text-center">
        <Typography variant="h2" component="h2" className="mt-10">
          404: Page Not Found
        </Typography>
        <Typography className="text--muted">
          You either tried some shady route or you came here by mistake.
          Whichever it is, try using the navigation.
        </Typography>
        <img
          src="/assets/images/error.svg"
          alt="404 not found"
          className="mt-5"
        />
        <div className="mt-5">
          <Button component={Link} to="/app" variant="outlined">
            Dashboard
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default NotFoundPage;
