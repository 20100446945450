import { showSnackBar } from './snackbar.actions';
import Financing from '../../services/financing.service';
import fileDownload from 'js-file-download';

/** Actions types ***/

export const SET_APPLICATIONS = '[APPLICATIONS] SET';
export const SET_APPLICATIONS_LOADING = '[APPLICATIONS] SET LOADING';

//--------------------------
/** Actions **/

/**
 *
 * @param {Array} applications
 */
export const setApplications = applications => {
  return {
    type: SET_APPLICATIONS,
    applications,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setApplicationsLoading = loading => {
  return {
    type: SET_APPLICATIONS_LOADING,
    loading,
  };
};

//--------------------------
/** Action creators **/

export const loadApplications = filters => {
  return dispatch => {
    dispatch(setApplicationsLoading(true));
    Financing.listApplications(filters)
      .then(response => {
        dispatch(setApplications(response.data));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load Applications',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setApplicationsLoading(false));
      });
  };
};

export const exportApplications = filters => {
  return dispatch => {
    dispatch(setApplicationsLoading(true));
    Financing.exportApplications(filters)
      .then(response => {
        fileDownload(response.data.data.csv, 'filename.csv');
        dispatch(setApplicationsLoading(false));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load Applications',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setApplicationsLoading(false));
      });
  };
};
