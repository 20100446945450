import * as Actions from '../actions/snackbar.actions';

const initialState = {
  visible: false,
  message: '',
  type: 'success',
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SNACKBAR_SHOW: {
      return {
        ...state,
        visible: true,
        message: action.options.message,
        type: action.options.type,
      };
    }
    case Actions.SNACKBAR_HIDE: {
      return {
        ...state,
        visible: false,
        message: '',
        type: 'success',
      };
    }

    default: {
      return state;
    }
  }
};

export default handle;
