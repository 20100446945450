import Banners from '../../services/banners.service';
import { showSnackBar } from './snackbar.actions';
import history from '../../history';

/** Actions types ***/

export const SET_BANNERS = '[BANNERS] SET';
export const SET_BANNERS_LOADING = '[BANNERS] SET LOADING';
export const SET_CURRENT_BANNER = '[BANNERS] SET CURRENT';
export const SET_CURRENT_BANNER_LOADING = '[BANNERS] SET CURRENT LOADING';

//--------------------------
/** Actions **/

/**
 *
 * @param {Array} banners
 */
export const setBanners = banners => {
  return {
    type: SET_BANNERS,
    banners,
  };
};

/**
 *
 * @param {Object} banner
 */
export const setCurrentBanner = banner => {
  return {
    type: SET_CURRENT_BANNER,
    banner,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setBannersLoading = loading => {
  return {
    type: SET_BANNERS_LOADING,
    loading,
  };
};
/**
 *
 * @param {Boolean} loading
 */
export const setCurrentBannerLoading = loading => {
  return {
    type: SET_CURRENT_BANNER_LOADING,
    loading,
  };
};

//--------------------------
/** Action creators **/

/**
 *
 * @param {String} status
 */
export const loadBanners = status => {
  return dispatch => {
    dispatch(setBannersLoading(true));
    Banners.list(status)
      .then(response => {
        dispatch(setBanners(response.data));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load Banners',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setBannersLoading(false));
      });
  };
};

/**
 *
 * @param {Number} id
 */
export const getBanner = id => {
  return dispatch => {
    dispatch(setCurrentBannerLoading(true));
    Banners.get(id)
      .then(response => {
        dispatch(setCurrentBanner(response.data));
      })
      .catch(error => {
        dispatch(setCurrentBanner({}));
        dispatch(
          showSnackBar({
            message: 'Could not load banner',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setCurrentBannerLoading(false));
      });
  };
};

/**
 *
 * @param {Number} id
 */
export const deleteBanner = id => {
  return (dispatch, getState) => {
    Banners.delete(id)
      .then(() => {
        const { list } = getState().banners;
        dispatch(setBanners(list.filter(el => el.id !== id)));
        dispatch(setCurrentBanner({}));
        dispatch(
          showSnackBar({
            message: 'Banner was deleted',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showSnackBar({
            message: 'Could not delete banner',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        // dispatch(setCurrentBannerLoading(false));
      });
  };
};

/**
 *
 * @param {Object} banner
 */
export const createBanner = banner => {
  return dispatch => {
    Banners.create(banner)
      .then(response => {
        dispatch(
          showSnackBar({
            message: 'Banner was created',
            type: 'success',
          }),
        );
        history.push('/app/offers/banners');
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showSnackBar({
            message: 'Could not create banner',
            type: 'error',
          }),
        );
      })
      .finally(() => {});
  };
};

/**
 *
 * @param {boolean} isActive
 */
export const setActive = (bannerId, isActive) => {
  return dispatch => {
    Banners.update(bannerId, {
      isActive: isActive,
    })
      .then(response => {
        dispatch(
          showSnackBar({
            message: isActive ? 'Banner active' : 'Banner inactive',
            type: 'success',
          }),
        );
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showSnackBar({
            message: 'Could not update banner',
            type: 'error',
          }),
        );
      })
      .finally(() => {});
  };
};
