import { combineReducers } from 'redux';
// functional
import offers from './offers.reducers';
import banners from './banners.reducers';
import applications from './financing.reducers';
import auth from './auth.reducers';
import file from './file.reducers';
import merchants from './merchants.reducers';
import categories from './categories.reducers';
import cashoutRequests from './cashouts.reducers';
// ui
import snackbar from './snackbar.reducers';
const mainReducer = combineReducers({
  offers,
  banners,
  snackbar,
  auth,
  file,
  merchants,
  cashoutRequests,
  applications,
  categories,
});

export default mainReducer;
