import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';

import Auth from '../../services/auth.service';
import ContractDebit from '../../pages/applications/Contracts/ContractDebit';
import ContractCredit from '../../pages/applications/Contracts/ContractCredit';
import ConnectContractDefault from '../../pages/applications/Contracts/ConnectContractDefault';

class WithoutLayout extends React.Component {
  state = { permissions: Auth.getPermissions() };

  routes = [
    {
      path: '/without-layout/applications/contracts-debit',
      component: (
        <Suspense fallback={<div>Loading...</div>}>
          <ContractDebit />
        </Suspense>
      ),
    },
    {
      path: '/without-layout/applications/contracts-credit',
      component: (
        <Suspense fallback={<div>Loading...</div>}>
          <ContractCredit />
        </Suspense>
      ),
    },
    {
      path: '/without-layout/applications/contracts/connect/default',
      component: (
        <Suspense fallback={<div>Loading...</div>}>
          <ConnectContractDefault />
        </Suspense>
      ),
    },
  ];

  render() {
    return (
      <Switch>
        {this.routes.map(el => (
          <Route exact path={el.path} key={el.path}>
            <Suspense fallback={<div>Loading...</div>}>
              {' '}
              {el.component}
            </Suspense>
          </Route>
        ))}

        <Route exact path="/without-layout"></Route>
      </Switch>
    );
  }
}
export default WithoutLayout;
