import * as Actions from '../actions/categories.actions';

const initialState = {
  paginatedCategories: {
    items: [],
    total: 0,
    totalPages: 0,
  },
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_CATEGORIES_LIST: {
      return {
        ...state,
        paginatedCategories: {
          ...action.paginatedCategories,
          totalPages: Math.ceil(action.paginatedCategories.totalCount / 20),
        },
      };
    }

    default: {
      return state;
    }
  }
};

export default handle;
