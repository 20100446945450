import * as Actions from '../actions/merchants.actions';

const initialState = {
  merchantLoading: false,
  pupularLoading: false,
  popularMerchants: [],
  merchant: {},
  paginatedMerchants: {
    items: [],
    total: 0,
    totalPages: 0,
  },
  aggregators: [],
};

const handle = function (state = initialState, action) {
  switch (action.type) {
    case Actions.SET_LOADING_MERCHANT: {
      return {
        ...state,
        merchantLoading: action.loading,
      };
    }
    case Actions.SET_LOADING_POPULAR_MERCHANTS: {
      return {
        ...state,
        pupularLoading: action.loading,
      };
    }
    case Actions.SET_MERCHANT_BY_ID: {
      return {
        ...state,
        merchant: { ...action.merchant },
      };
    }
    case Actions.SET_POPULAR_MERCHANTS: {
      return {
        ...state,
        popularMerchants: action.popularMerchants,
      };
    }

    case Actions.SET_MERCHANTS_LIST: {
      return {
        ...state,
        paginatedMerchants: {
          ...action.paginatedMerchants,
          totalPages: Math.ceil(action.paginatedMerchants.totalCount / 20),
        },
      };
    }

    case Actions.SET_MERCHANTS_AGGREGATORS: {
      return {
        ...state,
        aggregators: action.aggregators,
      };
    }

    default: {
      return state;
    }
  }
};

export default handle;
