import Merchants from '../../services/merchants.service';
import { showSnackBar } from './snackbar.actions';

/** Actions types ***/

export const SET_POPULAR_MERCHANTS = '[MERCHANT] SET POPULAR';
export const SET_MERCHANT_BY_ID = '[MERCHANT] SET BY ID';
export const SET_LOADING_MERCHANT = '[MERCHANT] SET LOADING';
export const SET_LOADING_POPULAR_MERCHANTS = '[MERCHANT] SET LOADING POPULAR';
export const SET_MERCHANTS_LIST = '[MERCHANT] SET LIST';
export const SET_MERCHANTS_AGGREGATORS = '[MERCHANT] SET AGGREGATORS';

//--------------------------
/** Actions **/

/**
 *
 * @param {ARRAY} popularMerchants
 */
export const setPopularMerchants = popularMerchants => {
  return {
    type: SET_POPULAR_MERCHANTS,
    popularMerchants,
  };
};

/**
 *
 * @param {Object} merchant
 */
export const setMerchantById = merchant => {
  return {
    type: SET_MERCHANT_BY_ID,
    merchant,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setMerchantLoading = loading => {
  return {
    type: SET_LOADING_MERCHANT,
    loading,
  };
};

/**
 *
 * @param {Boolean} loading
 */
export const setPopularMerchantsLoading = loading => {
  return {
    type: SET_LOADING_POPULAR_MERCHANTS,
    loading,
  };
};

/**
 *
 * @param {Object} paginatedMerchants
 */
export const setMerchantsList = paginatedMerchants => {
  return {
    type: SET_MERCHANTS_LIST,
    paginatedMerchants,
  };
};

/**
 *
 * @param {Array} aggregators
 */
export const setMerchantsAggregators = aggregators => {
  return {
    type: SET_MERCHANTS_AGGREGATORS,
    aggregators,
  };
};
//--------------------------
/** Action creators **/

/**
 * load list of popular merchants from db
 */

export const getPopulatMerchants = () => {
  return dispatch => {
    dispatch(setPopularMerchantsLoading(true));
    Merchants.getPopular()
      .then(response => {
        dispatch(
          setPopularMerchants(
            response.data.map(el => ({ ...el, title: el.name })),
          ),
        );
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load popular merchants',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setPopularMerchantsLoading(false));
      });
  };
};

/**
 * @param {Array} popularMerchants
 */

export const savePopularMerchants = popularMerchants => {
  return dispatch => {
    dispatch(setPopularMerchantsLoading(true));
    Merchants.savePopular(popularMerchants)
      .then(response => {
        dispatch(
          showSnackBar({
            message: 'Popular merchants were saved',
            type: 'success',
          }),
        );
        if (response) {
          dispatch(
            setPopularMerchants(
              response.data.map(el => ({ ...el, title: el.name })),
            ),
          );
        }
      })
      .catch(error => {
        console.log(error);
        dispatch(
          showSnackBar({
            message: 'Could not save popular merchants',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setPopularMerchantsLoading(false));
      });
  };
};

/**
 *
 * @param {Number} merchantId
 */
export const getMerchantById = merchantId => {
  return dispatch => {
    dispatch(setMerchantLoading(true));
    Merchants.getMerchantAffiliate(merchantId)
      .then(response => {
        dispatch(
          setMerchantById({ ...response.data, title: response.data.name }),
        );
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not find an merchant with entered id',
            type: 'error',
          }),
        );
      })
      .finally(() => {
        dispatch(setMerchantLoading(false));
      });
  };
};

/**
 *
 * @param {Object} filters
 */
export const getMerchantsList = filters => {
  return dispatch => {
    Merchants.listMerchantsAffiliate(filters)
      .then(response => {
        dispatch(setMerchantsList({ ...response.data }));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load the list of merchants',
            type: 'error',
          }),
        );
      });
  };
};

export const getAggregatorsList = () => {
  return dispatch => {
    Merchants.listAffiliateAggregators()
      .then(response => {
        dispatch(setMerchantsAggregators(response.data));
      })
      .catch(error => {
        dispatch(
          showSnackBar({
            message: 'Could not load the list of aggregators',
            type: 'error',
          }),
        );
      });
  };
};
