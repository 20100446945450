import axios from './axios.service';

export default class ContractsService {
  static auditDebitContract(applicationUserId) {
    return axios.post(`/api/v2/cmt-backend/contracts/debit/audit`, {
      applicationUserId: applicationUserId,
    });
  }

  static auditCreditContract(applicationUserId) {
    return axios.post(`/api/v2/cmt-backend/contracts/credit/audit`, {
      applicationUserId: applicationUserId,
    });
  }
}
