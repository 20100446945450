import React, { Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  CssBaseline,
  IconButton,
  Snackbar,
  SnackbarContent,
  ThemeProvider,
  unstable_createMuiStrictModeTheme as createMuiTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import history from '../../history';

import Auth from '../../services/auth.service';
// redux actions
import {
  hideSnackBar,
  isAuthenticated,
  logout,
  setAuthUser,
  setIsAuthenticated,
} from '../../store/actions';

import ProtectedRoute from '../../components/ProtectedRoute/ProtectedRoute';
import { DefaultLayoutEnabledRoutes } from '../../routes/constants';
import { RouteManager } from '../../routes/route-manager';
import CMTDrawer from '../../components/CMTDrawer';
import CMTAppBar from '../../components/CMTAppBar';
import './style.scss';

class DefaultLayout extends React.Component {
  state = { permissions: Auth.getPermissions(), showMenu: false };

  componentDidMount() {
    // check if user is not logged in but we sill have a refresh token
    if (!Auth.isLoggedIn()) {
      Auth.logout();
    }
  }
  theme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#fbc02d',
      },
      secondary: {
        main: '#ef5350',
      },
    },
  });

  onHamburgerClick = () => {
    this.setState({ showMenu: true });
  };

  onCloseDrawerClick = () => {
    this.setState({ showMenu: false });
  };

  render() {
    const { hideSnackBar, snackbar, logout } = this.props;
    return (
      <ThemeProvider theme={this.theme}>
        <CssBaseline />

        <div className="default-layout">
          <CMTAppBar
            className={'app-bar'}
            onLogoutClick={logout}
            onHamburgerClick={this.onHamburgerClick}
          />

          <CMTDrawer
            className={'drawer'}
            permissions={this.state.permissions}
            show={this.state.showMenu}
            onCloseClick={this.onCloseDrawerClick}
          />

          <main className="default-layout--content">
            <Switch>
              {DefaultLayoutEnabledRoutes.map(({ path, LazyComponent }) => (
                <ProtectedRoute
                  key={path}
                  path={path}
                  userPermissions={this.state.permissions}
                >
                  <Route exact path={path}>
                    <Suspense
                      fallback={
                        <Box sx={{ display: 'flex' }}>
                          <CircularProgress />
                        </Box>
                      }
                    >
                      {' '}
                      <LazyComponent />
                    </Suspense>
                  </Route>
                </ProtectedRoute>
              ))}

              <Route exact path="/app">
                <Redirect
                  to={RouteManager.getFirstAllowedRoute(this.state.permissions)}
                />
              </Route>
            </Switch>
          </main>
          <Snackbar
            open={snackbar.visible}
            autoHideDuration={6000}
            onClose={hideSnackBar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <SnackbarContent
              message={snackbar.message}
              className={snackbar.type}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={hideSnackBar}
                >
                  <CloseIcon />
                </IconButton>
              }
            />
          </Snackbar>
        </div>
      </ThemeProvider>
    );
  }
}

function mapStateToProps({ snackbar, auth }) {
  return {
    snackbar,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    hideSnackBar: () => dispatch(hideSnackBar()),
    setAuthUser: user => dispatch(setAuthUser(user)),
    setIsAuthenticated: isLoggedin => dispatch(setIsAuthenticated(isLoggedin)),
    ...bindActionCreators(
      {
        logout: logout,
        isAuthenticated: isAuthenticated,
      },
      dispatch,
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
