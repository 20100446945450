import React from 'react';
import CMTMenu from '../CMTMenu';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from '@material-ui/core';
import './style.scss';
import CloseIcon from '@material-ui/icons/Close';

export default function CMTDrawer({
  className = '',
  permissions,
  show = false,
  onCloseClick,
}) {
  return (
    <Drawer
      className={`${className} cmt-drawer ${show ? 'cmt-drawer--visible' : ''}`}
      variant="permanent"
      classes={{
        paper: 'paper',
      }}
      anchor="left"
    >
      <IconButton className={'close'} component="label" onClick={onCloseClick}>
        <CloseIcon />
      </IconButton>

      <List className={'list'}>
        <ListItem>
          <ListItemText
            primary={`Menu (${window.env.REACT_APP_ENVIRONMENT})`}
          />
        </ListItem>

        <Divider />
      </List>

      <CMTMenu userPermissions={permissions} onItemClick={onCloseClick} />
    </Drawer>
  );
}
