import React, { useEffect } from 'react';
import './styles.css';
import { useLocation } from 'react-router';

import { IdentityGender } from '../../../helpers/constants';
import ContractsService from '../../../services/constracts.service';

const ContractDebit = props => {
  const location = useLocation();
  const { application } = location.state;

  const expirationDate = application.expirationDate
    ? new Date(application.expirationDate)
    : null;
  const birthdate = application.birthdate
    ? new Date(application.birthdate)
    : null;

  useEffect(function () {
    ContractsService.auditDebitContract(application.userId);
    window.print();
  });

  return (
    <>
      <div className="contract-body body" dir="rtl">
        <h3 className="text-center underline">
          طلب إصدار بطاقة دفع مطبوعة للعملاء{' '}
        </h3>
        <p>
          <span className="ml-3">
            الاسم بالكامل (كما فى بطاقة الرقم القومى):
          </span>
          <span
            className="underlineDotted"
            contentEditable="true"
            style={{ minWidth: '400px' }}
          >
            {application.firstName} {application.secondName}{' '}
            {application.thirdName} {application.familyName}
          </span>
        </p>
        <p>
          <span className="ml-3">الرقم القومي: </span>
          <span className="underlineDotted" contentEditable="true">
            {application.documentNumber}
          </span>
          <span className="ml-3 mr-7">تاريخ انتهاء صلاحية البطاقة:</span>
          <span className="underlineDotted width50" contentEditable="true">
            {expirationDate?.getUTCDate()}
          </span>
          <span className="ml-1 mr-1">/</span>
          <span className="underlineDotted width50" contentEditable="true">
            {expirationDate ? expirationDate.getUTCMonth() + 1 : null}
          </span>
          <span className="ml-1 mr-1">/</span>
          <span className="underlineDotted width50" contentEditable="true">
            {expirationDate?.getUTCFullYear()}
          </span>
        </p>

        <p>
          <span className="ml-3"> تاريخ الميلاد:</span>
          <span
            className="underlineDotted"
            style={{ minWidth: '30px' }}
            contentEditable="true"
          >
            {birthdate?.getUTCDate()}
          </span>
          <span className="ml-1 mr-1">/</span>
          <span
            className="underlineDotted"
            style={{ minWidth: '30px' }}
            contentEditable="true"
          >
            {birthdate ? birthdate.getUTCMonth() + 1 : null}
          </span>
          <span className="ml-1 mr-1">/</span>
          <span
            className="underlineDotted"
            style={{ minWidth: '40px' }}
            contentEditable="true"
          >
            {birthdate?.getUTCFullYear()}
          </span>
          <span className="ml-3 mr-7">محل الميلاد: </span>
          <span
            className="underlineDotted"
            style={{ minWidth: '200px' }}
            contentEditable="true"
          >
            {application.placeOfBirth}
          </span>
          <span className="ml-1 mr-7">النوع:</span>
          <span className="mr-2">ذكر</span>
          <span
            className={`square ${
              application.gender === IdentityGender.MALE ? 'cross' : ''
            }`}
          ></span>
          <span className="mr-3">أنثى</span>
          <span
            className={`square ${
              application.gender === IdentityGender.FEMALE ? 'cross' : ''
            }`}
          ></span>
        </p>

        <p>
          <span className="ml-3">رقم الهاتف المحمول:</span>
          <span className="underlineDotted" contentEditable="true">
            {application.phone}
          </span>
        </p>

        <p>
          <span className="ml-3">عنوان محل الاقامة الدائم الحالى: </span>
          <span
            className="underlineDotted"
            style={{ minWidth: '400px' }}
            contentEditable="true"
          >
            {application.address}
          </span>
        </p>
        <p>
          <span className="ml-3"> المهنة و جهة العمل : </span>
          <span
            className="underlineDotted"
            contentEditable="true"
            style={{ minWidth: '300px' }}
          >
            {application.profession}
          </span>
        </p>
        <p>
          <span className="ml-3">عنوان العمل:</span>
          <span
            className="underlineDotted"
            style={{ minWidth: '400px' }}
            contentEditable="true"
          ></span>
        </p>

        <p>
          <span className="ml-3"> نوع النشاط في حالة العميل صاحب عمل : </span>
          <span
            className="underlineDotted"
            style={{ minWidth: '300px' }}
            contentEditable="true"
          ></span>
        </p>

        <p>
          <span className="ml-1">
            هل أنت مواطن أمريكي أو مقيم في الولايات المتحدة الأمريكية أو حامل
            البطاقة الخضراء أو ولدت في الولايات المتحدة؟{' '}
          </span>
          <span className="mr-2">نعم</span>
          <span className="square"></span>
          <span className="mr-2">لا</span>
          <span className="square"></span>
        </p>

        <p>
          <span className="ml-1">
            أنا أعتبر حاليا أو سابقا (من ذوى المناصب العامة العليا):
          </span>
          <span className="mr-2">نعم</span>
          <span className="square"></span>
          <span className="mr-2">لا</span>
          <span className="square"></span>
        </p>

        <p>
          <span className="ml-1">
            لى ارتباط مباشر بمن يشغل حاليا أو سابقا أحد المناصب العامة العليا:
          </span>
          <span className="mr-2">نعم</span>
          <span className="square"></span>
          <span className="mr-2">لا</span>
          <span className="square"></span>
        </p>

        <p>بموجب هذا الطلب يتعهد المستخدم ويقر بما يلى:</p>

        <ol className="dots terms-list">
          <li>
            أقر أن كافة البيانات الواردة بهذا الطلب حقيقية وصحيحة وأتعهد بتحديث
            بياناتي فور حدوث أية تغييرات بشأنها أو عند الطلب أو عندما يطلب البنك
            ذلك في موعد اقصاه 30 يوما من تاريخ حدوث هذا التغيير، كما أقبل أن
            يحتفظ البنك بهذا الطلب والمستندات المرفقة فى حالة إلغاء الخدمة و أقر
            أنني المستفيد الحقيقي والحائز الوحيد لبطاقة الدفع المربوطة بالخدمة
            والمسجل رقمها بهذا الطلب.
          </li>
          <li>
            و أقر أن جميع المعاملات التى تتم من خلال الخدمة المذكورة هى معاملات
            مشروعة وتستخدم فى أغراض مشروعة ولأشخاص غير محظور التعامل معهم و أن
            جميع المعاملات تتم تحت مسئوليتى الشخصية.
          </li>
          <li>
            وأقر اننى قد اطلعت علي كافة شروط وأحكام خدمة الدفع عن طريق البطاقات
            المدفوعة مقدما، وأوافق علي شروط وأحكام هذا الطلب، كما أقر وأتعهد
            بتقديم المعلومات والمستندات اللازمة عند الطلب وحال الإقتداء.
          </li>
          <li>
            وأقر اننى قد اطلعت علي كافة شروط وأحكام الخدمة الموجودة علي تطبيق
            الهاتف المحمول لاكي ، وأوافق علي شروط وأحكام هذا الطلب{' '}
          </li>
          <li>
            وأقر أن موظف المبيعات قد قام بعرض كافة التفاصيل إلى و أنى على علم
            بكافة التفاصيل{' '}
          </li>
          <li>
            أقر أنني قد إطلعت على تعريف تولى المناصب العامة العليا أو الارتباط
            بمن يتولاها.
          </li>
          <li>
            أقر بان يعتبر توقيعي على هذا النموذج بمثابه موافقه على الإلتزام
            بكافة الشروط والأحكام الواردة بهذا الطلب وأي تعديلات أو إضافات يقوم
            البنك بإدخالها عليها تماشياً مع ما يصدر من قوانين أو لوائح أو
            إجراءات البنك الداخليه في هذا الشأن مع التزام البنك بموافاة العميل
            بها دورياً على النحو المبين من هذا الطلب.
          </li>
        </ol>

        <p>
          بموجب هذا الطلب يتعهد موظف مبيعات مقدم الخدمة ويقر بما يلى: أقر انني
          قد اطلعت علي اصل البطاقه الشخصيه للعميل
        </p>

        <p>
          <span className="ml-2"> توقيع مستخدم الخدمة </span>
          <span
            className="underlineDotted"
            contentEditable="true"
            style={{ minWidth: '170px' }}
          ></span>
          <span className="ml-2 mr-6">
            توقيع توقيع موظف مبيعات مقدم الخدمة :
          </span>
          <span
            className="underlineDotted"
            contentEditable="true"
            style={{ minWidth: '170px' }}
          ></span>
        </p>

        <p>
          <span className="ml-3"> التاريخ :</span>
          <span className="underlineDotted" contentEditable="true"></span>
          <span className="ml-3 mr-12"> التاريخ :</span>
          <span className="underlineDotted" contentEditable="true"></span>
        </p>

        <p>
          تم مراجعة الطلب بمعرفتي وتم التأكد من كافة البيانات المسجلة بالطلب و
          مطابقتها بالبيانات التي تم تسجيلها علي النظام:
        </p>

        <p>
          <span className="ml-3"> توقيع الموظف المراجع:</span>
          <span className="underlineDotted" contentEditable="true"></span>
        </p>

        <div className="border">
          <h3 className="text-center">
            البيانات الخاصة بمراجعة الطلب من جانب البنك
          </h3>

          <div dir="ltr" style={{ textAlign: 'left' }}>
            <div style={{ display: 'flex' }}>
              <div style={{ flexBasis: '50%' }}>
                <span className="mr-1"> Risk Level:</span>
                <span className="mr-1 ml-2">L1</span>
                <span className="square"></span>
                <span className="mr-1 ml-2">L2</span>
                <span className="square"></span>
                <span className="mr-1 ml-2">L3</span>
                <span className="square"></span>
              </div>
              <div style={{ flexBasis: '50%' }}>
                <span className="mr-3">Risk Matrix:</span>
                <span className="underlineDotted" contentEditable="true"></span>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ flexBasis: '50%' }}>
                <p>Purpose of account opening: Prepaid card – Lucky</p>
              </div>
              <div style={{ flexBasis: '50%' }}>
                <p>KYC Currency Amount: EGP </p>
              </div>
            </div>

            <div style={{ display: 'flex' }}>
              <div style={{ flexBasis: '50%' }}>
                <span className="mr-3">Bank Maker Signature:</span>
                <span
                  className="underlineDotted"
                  contentEditable="true"
                  style={{ minWidth: '170px' }}
                ></span>
              </div>
              <div style={{ flexBasis: '50%' }}>
                <span className="mr-3">Bank Checker Signature:</span>
                <span
                  className="underlineDotted"
                  contentEditable="true"
                  style={{ minWidth: '170px' }}
                ></span>
              </div>
            </div>
          </div>
        </div>
        <div className="page-break"></div>
        <h3 className="text-center">شروط وأحكام عامة:</h3>
        <p>
          تمهيد: يتضمن هذا الطلب شروط وأحكام استخدام خدمة الدفع عن طريق البطاقات
          المدفوعة مقدما، والتي بموجبها يستطيع المستخدم فتح حساب بطاقة مدفوعة
          مقدما لدي البنك للتعامل عليها سواء بالإيداع، أو السحب أو التحويل
          الإلكتروني لأموال، ويودع بالحساب رصيد إلكتروني (وحدات النقود
          الإلكترونية) بمقدار ما أودعه المستخدم في حساب البطاقة لدي البنك ومن ثم
          يتعين علي المستخدم قراءة كافة شروط وأحكام استخدام الخدمة بدقة وعناية
          قبل التوقيع.
        </p>
        <ol className="dots terms-list-compact">
          <li>
            فور توقيع المستخدم على طلب الاشتراك في الخدمة بشرط استيفائه باقي
            الشروط الأخري، واستكمال إجراءات فتح البطاقة المدفوعة مقدما وبعد
            التحقق من هويته من قبل الشركة، سيتم تفعيل خدمة الدفع عن طريق البطاقة
            المدفوعة مقدما من خلال منح المستخدم البطاقة الخاصة به الصادرة من
            شركة ميزة و يتم تسليم المستخدم الMailer PIN الخاصة به (و رابط لتحميل
            التطبيق على متجر التطبيقات مع إجراءات التسجيل المطلوب القيام بها من
            قبل المستخدم) عند التسجيل بالخدمة وعلى المستخدم القيام بعملية
            التسجيل مرة أخرى من خلال التطبيق في حالة تغيير المستخدم لهاتفه
            المحمول و يحق للعميل أن يتعامل على رصيده الإلكتروني من خلال إيداع أو
            سحب مبالغ من كافة ماكينات الصرف الآلي الخاصة ببنك المشرق، وأيضا
            الشراء من خلال نقاط البيع الإلكترونية و الشراء عبر الإنترنت، وذلك في
            ضوء المحددات الموضوعة من قبل البنك و البنك المركزي في هذا الشأن.
          </li>
          <li>
            يحق لمستخدم الخدمة في أي وقت إغلاق حساب البطاقة المدفوعة مقدما و
            استرداد رصيد النقدية التي تقابل رصيده الإلكتروني الدائن وذلك بتوجه
            مستخدم الخدمة إلى احد فروع الشركة لسحب كامل رصيده المتبقي و تتم
            عملية الإغلاق ووقف الخدمة في خلال 15 يوم عمل من استلام طلب الأغلاق
            مع مسح كافة بيانات المستخدم تباعا او احتفاظ المستخدم بحقة في عدم
            اطلاع الشركة على بياناته عند طلبه اغلاق حسابه، ولا يحق لمستخدم
            الخدمة بمطالبة البنك أو الشركة أو الرجوع عليها بوجود رصيد متبقى بعد
            مرور القترة المذكورة و ذلك دون ادنى مسئولية على البنك أو الشركة.
          </li>
          <li>
            يقتصر الحق في استخدام الخدمة أو الإنتفاع بها على المستخدم وحده دون
            غيره، ويلتزم المستخدم في جميع الأوقات بعدم السماح أو تمكين أي شخص
            أخر من استخدامها أو الإنتفاع بها.
          </li>
          <li>
            يحق للبنك و/أو للشركة وفقا لمطلق تقديره إيقاف الخدمة عن المستخدم أو
            إلغاؤها وذلك في حالة استخدامه للخدمة بالمخالفة إلى شروط وأحكام خدمة
            الدفع عبر البطاقات المدفوعة مقدما أو بالمخالفة إلى القوانين واللوائح
            الواجبة التطبيق.
          </li>
          <li>
            يحق للبنك تقييد أو إغلاق حساب المستخدم دون الرجوع الى المستخدم في أي
            وقت حسب الاقتضاء إذا كان ذلك مطلوبا بموجب القانون أو الاتفاقيات
            الدولية أو إذا كان لدى البنك أسباب معقولة أو مؤيدة لاحتيال المستخدم
            أو استخدامه الحساب لغرض غير مشروع أو غير لائق أو بما يخالف القوانين
            السارية أو بما يخالف شروط وأحكام الحسابات المصرفية المطبقة بالبنك،
            كما يجوز للبنك أيضا تجميد أو إغلاق الحساب في حالة كون المستخدم ضحية
            للاحتيال أو سرقة الهوية من أجل تفادي الخسائر المحتملة
          </li>
          <li>
            يحق للبنك أن يجمد أو يوقف تشغيل الحساب أو يغلق الحساب وأن يرفض أي من
            المعاملات المصرفية إذا كان صاحب الحساب مخلاً بأي من شروط وأحكام هذا
            الطلب أو إذا كانت قدمت للبنك أي تعليمات غامضة أو متناقضة أو غير
            مقبولة من البنك أو إذا شك البنك في وجود حالات غش أو عدم قانونية أو
            مشروعية في أي معاملات ، كما يجوز للبنك أن يبلغ عن أي عمليات غسل
            أموال أو معاملات مشكوك فيها أو غير قانونية في الحساب إلى السلطات
            الرقابية بجمهورية مصر العربية أو بالخارج حيث يلتزم البنك بجميع
            قوانين مكافحة غسل الأموال ونظمها السارية ويجوز له و فقا لتقديره
            الخاص أن يطلب تفاصيل أو معلومات إضافية من صاحب الحساب وأن يقدم تلك
            المعلومات والتفاصيل إلى السلطات المعنية وفي تلك الحالات لن يتحمل
            البنك أي مسؤولية أو التزام عن تأخير تنفيذ أي معاملات إذا لم تقدم
            المعلومات المطلوبة من قبل صاحب الحساب في الموعد المطلوب.
          </li>
          <li>
            يلتزم المستخدم بالمحافظة على رقمه السري وإبقائه في جميع الأوقات تحت
            مسئوليته الشخصية، وتبعا لذلك سوف يكون المستخدم وحده مسؤولاً عن إفشاء
            أي من البيانات أو المعلومات المتعلقة به أو بأي من حساباته إذا كان
            ذلك الإفشاء ناشئا عن خطأ أو تقصير أو إهمال في المحافظة على رقمه
            السري الخاص به دون أدني مسئولية على البنك و/أو الشركة.
          </li>
          <li>
            يقر المستخدم بمسؤوليته عن جميع المعاملات وكافة العمليات التي تتم على
            حساب البطاقة المرتبطة بالخدمة وباستخدام رقمه السري وسوف تكون دفاتر
            البنك و سجلاته حجة على المستخدم والغير.
          </li>
          <li>
            لن يكون البنك و/أو الشركة مسؤول عن أية خسائر أو اضرار قد تحدث
            للمستخدم بسبب سوء أو إساءة استخدامه للخدمة أو نتيجة الي خطأ يرتكبه
            أو نتيجة أي خلل قد يحدث للخدمة بسبب خطأ من جانبه أو لعدم كفاية رصيد
            حساب البطاقة أو الى سبب آخر قد يكون للمستخدم دخل فيه ولن يكون البنك
            و/أو الشركة مسؤلة عن أي خلل في شبكات الاتصالات ويقر بعدم مسئولية
            البنك و/أو الشركة تحمل أية تكاليف أو أعباء قد تحدث نتيجة انقطاع
            الخدمة بشبكة الانترنت أو من جراء أية مشاكل أو اعطال قد تطرأ على
            الشبكة.
          </li>
          <li>
            لن يكون البنك و/أو الشركة مسؤول عن رفض أي مؤسسة قبول الرصيد
            الإلكتروني كوسيلة سداد كما أنه لن يكون مسؤول عن البضائع أو الخدمات
            التي سوف يحصل عليها المستخدم في مقابل ذلك الرصيد، وأى شكوى من
            المستخدم في هذا الصدد يجب عليه حلها مع تلك المؤسسة مباشرة وسوف يقتصر
            دور البنك في هذا الخصوص على إضافة أي مبلغ قد يسترده المستخدم إلى
            حساب البطاقة وذلك بعد حصول البنك على سند إضافة صادر بشكل صحيح من تلك
            المؤسسة.
          </li>
          <li>
            يتعين على المستخدم تبليغ الشركة في حالة سرقة البطاقة او الهاتف
            المحمول أو فقد احد منهم و في جميع الاحوال في حالة قيام شخص اخر
            بإجراء أي تعاملات على حسابات المستخدم من خلال تلك الخدمة و قبل قيام
            المستخدم بإبلاغ الشركة يكون المستخدم مسؤلاً مسئولية كاملة تجاه البنك
            و/أو الشركة عن كافة النتائج المترتبة على تلك المعاملات ويقوم
            المستخدم بالإبلاغ عن طريق الاتصال بخدمة العملاء الخاصة بالشركة لوقف
            الخدمة و في حالة رغبة المستخدم اعادة تفعيل الخدمة يجب عليه الاتصال
            بخدمة العملاء الخاصة بالشركة.
          </li>
          <li>
            يحق للبنك وضع حدود قصوى لحجم رصيد البطاقة وقيمة و عدد كل من العمليات
            اليومية والشهرية ، كما يحق للبنك تغييرها من وقت لأخر مع اخطار
            المستخدم باى وسيلة مناسبة يراها البنك و يعتبر استخدام المستخدم
            للخدمة بعد تغيير تلك الحدود بمثابة موافقة منه ويتم ذلك في ضوء سياسة
            البنك ووفقا للقواعد المنظمة لهذه الخدمة والصادرة من البنك المركزي
            المصري و تتم عمليات التحويل داخل جمهورية مصر العربية فقط وبالعملة
            المحلية(الجنيه المصري) فقط و لا يسمح بتبادل عملات أخري أو إجراء
            عمليات تبادل للعملات أو مقصة بين حسابات المستخدمين ذات العملات
            الأخرى و يسمح لمستخدمي الخدمة بتلقي تحويلات من الخارج بالعملات
            الأجنبية ويتم إضافتها إلي حسابه بالجنيه المصري، علي أن يكون المستخدم
            قد خضع لاجراءات التعرف علي الهوية بموجب قواعد التعرف علي الهوية
            الصادرة عن وحدة غسيل الاموال وتمويل الارهاب التابعة للبنك المركزي
            المصري.
          </li>
          <li>
            يحق للبنك رفض تنفيذ بعض العمليات في أي من الحالات التي يراها البنك
            غير مناسبة كما يحق له عدم الافصاح عن الاسباب ويتخذ البنك الاجراءات
            المناسبة للتأكد من أن التحويل يخص ذات المستخدم وأن القيمة قد تم
            إضافتها بالجنيه المصري لحساب البطاقة الخاصة به وعلى المستخدم حماية
            الرقم السري الخاص به و الابلاغ فوراً عند فقد بطاقته.
          </li>
          <li>
            في حالة وجود شكوى او نزاع مالى نتيجة استخدام الخدمة من قبل المستخدم
            فليتوجه لأقرب فرع من فروع الشركة والتقدم بالشكوى او النزاع موقعا
            عليها أو من خلال الاتصال بأرقام خدمة العملاء الخاصة بالشركة أوالخط
            الساخن <strong>19677</strong> وسيتم الرد عليه خلال عشر أيام عمل (10
            يوم) من تاريخ تقديم الشكوى عدا الشكاوى المتعلقة بمعاملات الخارجية
            ويتم اخطار المستخدم بالمدة اللازمة لدراسة الشكوى.
          </li>
          <p style={{ margin: '10px 0px' }}>
            تولى المناصب العامة العليا أو الارتباط بمن يتولاها:
          </p>
          <li>
            وفقا للتعريف، هم الاشخاص الذين يشغلون مناصب عامة عليا فى مصر او في
            دولة اجنبية أو منظمة دولية سواء حاليا أو سابقا، و على سبيل المثال لا
            الحصر:
          </li>
          <li>
            رؤساء الدول أو الحكومات و أفراد العائلات المالكة و الحاكمة بأى دولة
            و الوزراء و نوابهم و كبار مساعديهم، ورؤساء و أعضاء المجالس النيابية
            و المجالس المحلية و البلديات، المحافظين ونوابهم و رؤساء المدن
            ونوابهم و العمد ونوابهم ورؤساء و أعضاء
          </li>
          <li>
            المجالس المتخصصة( مثل :المجلس القومى للمرأة، المجلس القومى لحقوق
            الإنسان )وكبار السياسيين، وكبار مسئولي الحكومة والمسئولين العسكريين
            والمسئولين الرئيسيين في الجهات القضائية( على سبيل المثال :رئيس
            المحكمة الدستورية و نوابه، رئيس المجلس الأعلى للقضاء ونوابه، رئيس
            محكمة النقض و نوابه، رئيس محكمة الاستئناف و نوابه، النائب العام و
            كبار مساعديه)، ورؤساء الشركات المملوكة للدولة و نوابهم و كبار
            مساعديهم، و رؤساء و أعضاء الهيئات العليا بالأحزاب السياسية و كبار
            الدبلوماسيين و المنظمات الدبلوماسية الدولية( على سبيل المثال : الامم
            المتحدة، اليونسكو، الخ)، رؤساء و أعضاء مجالس ادارة النقابات و رؤساء
            و أعضاء مجالس الجامعات الحكومية.
          </li>
        </ol>
      </div>
    </>
  );
};

export default ContractDebit;
