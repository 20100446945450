import React, { useEffect } from 'react';
import './styles.css';
import { useLocation } from 'react-router';
import ContractsService from '../../../services/constracts.service';

const CREDIT_LINE_PENDING_STATUS = 1;
const CREDIT_LINE_APPROVED_STATUS = 2;

const ContractCredit = props => {
  const location = useLocation();
  const { application } = location.state;

  const today = new Date();

  useEffect(function () {
    ContractsService.auditCreditContract(application.userId);
    window.print();
  });

  return (
    <>
      <div className="contract-body body" dir="rtl">
        <h1 class="text-center underline">عقد تقديم خدمة تقسيط</h1>
        <p>
          إنه في يوم <span className="underlineSolid"></span> الموافق{' '}
          <span class="underlineSolid" style={{ minWidth: '100px' }}>
            {' '}
            {today.getUTCDate()}/{today.getUTCMonth() + 1}/
            {today.getUTCFullYear()}{' '}
          </span>{' '}
          <b>("تاريخ النفاذ"):</b>
        </p>
        <p>تحرر هذا العقد بين كل من:</p>

        <ol>
          <li>
            شركة الكى للبرمجيات ش.م.م.، سجل تجاري 156882 ، والكائن مقرها الرئيسي
            في بمبني 12 أ الكيلو 28 الدور 3 القرية الذكية طريق مصر اإلسكندرية
            الصحراوي الجيزة مصر، ـشار إليها فيما بعد بـ " ُ (وي الشركة" أو
            "الطرف األول")؛
          </li>
          <li>
            <p>
              السيد(ة){' '}
              <span className="underlineSolid">
                {application.firstName} {application.secondName}{' '}
                {application.thirdName} {application.familyName}
              </span>
              ، مصري الجنسية، حامل بطاقة رقم قومي رقم{' '}
              <span className="underlineSolid" style={{ minWidth: '100px' }}>
                {application.documentNumber}
              </span>{' '}
            </p>
            <p>
              والكائن محل إقامته(ا){' '}
              <span className="underlineSolid" style={{ minWidth: '500px' }}>
                {application.floorFlatNumber
                  ? application.floorFlatNumber + '°'
                  : ''}
                {application.buildingNumber} {application.street}{' '}
                {application.disctrict} {application.city}{' '}
              </span>
            </p>
            <p>
              و رقم الهاف الشخصي الخاص بالتطبيق{' '}
              <span className="underlineSolid">{application.phone}</span>
            </p>
          </li>
          <li>
            (ويشار إليها فيما بعد بـ "العميل"أو "الطرف الثاني") و (يشار إلى طرفي
            هذا العقد منفردين بـ "الطرف" ومجتمعين بـ "الطرفين").{' '}
          </li>
        </ol>

        <h1 class="text-center underline">عقد تقديم خدمة تقسيط</h1>

        <ol class="arabic">
          <li>
            حيث إن الشركة من الشركا ت التي توفر خدمات الشراء بالتقسيط لمدة او
            فترة اقل من 6 شهور بواسطة التطبيق الكى المتوفر على متاجر Play Google
            وStore App ("التطبيق") كذلك من خالل شبكة التجار المكونة من مقدمي
            السلع والخدمات متعاقدين مع الشركة ("شبكة التجار").
          </li>
          <li>
            ولما كان العميل يرغب في االستفادة مما تقدمه الشركة من خدمات تمويلية
            لشراء المنتج أو الخدمة المنصوص عليها بصدر العقد ( "المنتج") خالل
            المدة المتفق عليها.
          </li>
          <li>
            {' '}
            وبعد أن أقر الطرفين بكامل أهليتهما القانونية للتعاقد والتصرف، تم
            االتفاق على:
          </li>
        </ol>

        <h1 class="text-center underline">البند (1) التمهيد</h1>
        <p>
          ً ً يعتبر التمهيد السابق جزءا من شروطه المكملة والمتممة له. ً من هذا
          العقد، ومكمالً ومتمماً لكل شروطه، وكذا المخاطبات الرسمية بين الطرفين
          جزءا
        </p>

        <h1 class="text-center underline">البند (2) موضوع العقد </h1>
        <ol class="dots">
          <li>
            تقوم الشركة بتوفير خدمة التقسيط للعميل والتي تمكنه من شراء السلع
            والخدمات االستهالكية من أي من شبكة التجار بالتقسيط على عدد شهور
            متساوية وفقا لشروط هذا العقد.
          </li>
          <li>
            يقوم العميل بشراء المنتجات وفقا للخطوات الواجب اتباعها وفقا لما هو
            منصوص عليه بالتطبيق ويكون توقيع العميل على هذا العقد بمثابة إقرار
            وقبول لكافة الخطو ات المنصوص عليها بالتطبيق.
          </li>
          <li>
            تحتفظ الشركة بملكية المنتجات لحين سداد كامل األقساط الشهرية وبشرط
            عدم التصرف فيها من قبل العميل (أو ترتيب أي حق عيني أو شخصي) – أيضا
            بحق شهر قيد الضمان عليها وفقا لقانون تنظيم الضمانات المنقولة الصادر
            بالقانون رقم 115 لسنة 2015 - ) وتلك شر و ط أساسية لهذا البيع لحين
            وفاء العميل بكامل األقساط الشهرية وفقا للشروط واألحكام الواردة بهذا
            العقد وكذا الشروط الخاصة بكل منتج.
          </li>
          <li>
            ً وافق العميل على الشروط واألحكام الخاصة بالتمويل وشروط الخصوصية
            المنصوص عليها بالتطبيق والتي يتم تعديلها من وقت ألخر كتابة بموجب
            إخطار للعميل.
          </li>
          <li>
            {' '}
            للشركة الحق في استيفاء سندات إذنيه من العميل تكون مستحقة األداء
            بالقدر المستخدم من التمويل الممنوح وكافة المبالغ المستحقة على العميل
            وفقا لهذا العقد بما في ذلك المصروفات اإلدارية والعوائد وفي حالة عدم
            سداد العميل لألقساط المستحقة عليه على أن يظل السند اإلذني طوال مدة
            صالحية التمويل الممنوح للعميل، ويلتزم الطرف األول بتسليم الضمانة
            للطرف ساريا الثاني أو مخالصة فور إنهاء العقد أو رغبة العميل في عدم
            االستفادة بالحد األقصى للتمويل وذلك بعد تسوية كافة المبالغ المستحقة
            على العميل.
          </li>
        </ol>

        <h1 class="text-center underline">البند (3) قيمة التمويل وبياناته</h1>
        <ol class="dots">
          <li>
            الحد االئتماني أو الحد األقصى لمبلغ الشراء هو مبلغ وقدره:{' '}
            <span className="underlineSolid" style={{ minWidth: '100px' }}>
              {
                application.financingPartner.find(partnerReview =>
                  [
                    CREDIT_LINE_PENDING_STATUS,
                    CREDIT_LINE_APPROVED_STATUS,
                  ].includes(partnerReview.creditLineStatus),
                )?.approvedAmount
              }
            </span>{' '}
            على أن يحق للشركة تعديل الحد األقصى وفقا لسلطتها المنفردة.
          </li>
          <li>
            تلتزم الشركة عند قبول كل عملية شراء موافاة العميل ببيان موضحا به
            القيمة المستخدمة من الحد األقصى لمبلغ الشراء وعدد األقساط (بشرط ان
            يكونوا على مدة أقل من 6 أشهر) وقيمة كل قسط شهري وموعد استحقاقه.
            يلتزم العميل بسداد األقساط الشهرية في اليوم الخامس (5) من كل شهر على
            أن يكون أول قسط مستحق السداد وفقا لما هو محدد بالبيان المرسل من قبل
            الشركة عند كل عملية شراء.
          </li>
          <li>
            {' '}
            في حالة عدم سداد أي من األقساط الشهرية في تاريخ استحقاق األقساط،
            يلتزم العميل بدفع نسبة{' '}
            <span
              className="underlineSolid"
              style={{ minWidth: '20px' }}
            ></span>{' '}
            كتعويض للشركة لما قد يصيبها من ضرر جراء ذلك ا لتأخير كتعويض اتفاقي
            عن كل يوم تأخير تسري من تاريخ حلول أجل القسط المتأخر ويتعدد التعويض
            بتعدد األقساط المتأخرة.
          </li>
          <li>
            يحق للعميل تعجيل الوفاء بكل أو بعض أقساط التمويل من تاريخ استحقاق
            ثاني قسط شريطة إبداء تلك الرغبة كتابة قبل ميعاد السداد المستهدف بمدة
            ال تقل عن ثالثين يوما على أن يتم سداد رصيد المديونية وفقا ً لجدول
            السداد المتفق عليه والمرفق بهذا العقد مع احتساب عمولة سداد المعجل
            قدرها (--%) بحد أقصى على الرصيد المتبقي من قيمة التمويل ويتم تحديدها
            كتابة للعميل قبل عملية تمويل على حدة.
          </li>
        </ol>

        <h1 class="text-center underline">البند (4) مدة العقد</h1>
        <ol class="dots">
          <li>
            {' '}
            يسري هذا العقد من تاريخ النفاذ ويظل ساريا لمدة عام قابلة للتجديد
            مالم يخطر أحد الطرفين الطرف األخر برغبته في إنهاء التعاقد بإخطار
            مسبق بثالثين يوما على األقل وفي حالة إنهاء العقد يجب تسوية كافة
            مستحقات الشركة قبل إتمام التعاقد على أن يتم تعليق استخدام الحد لمبلغ
            األقصى للشراء من تاريخ طلب اإلنهاء. و في كافة األحوال يظل العقد
            ساريا لحين الوفاء بكامل قيمة األقساط والمبالغ المستحقة على العميل
            لصالح الشركة وال يتم إنهاء العقد إال بعد إنهاء التسوية.{' '}
          </li>
          <li>
            {' '}
            يحق للشركة إنهاء هذا العقد فورا بدون الحاجة إلى سابق إعذار أو إنذار
            واتخاذ أي إجراء قانوني أخر في حالة إخالل العميل بأي من التزاماته
            المنصوص عليها بهذا العقد وعدم تصحيح ذلك خالل أربعة عشرة (14) يوما من
            تاريخ إخطاره بذلك بما في ذلك على سبيل المثال ال الحصر عدم سداد
            العميل لقسطين متتاليين أو في حالة رد المنتج للتاجر خالل مدد أقصاها
            المدد المنصوص عليها في قانون حماية المستهلك رقم 181 لسنة 2018{' '}
          </li>
        </ol>

        <h1 class="text-center underline">البند (5) شبكة التجار</h1>
        <ol class="dots">
          <li>
            تلتزم الشركة عند قبول كل عملية شراء بالتقسيط موافاة العميل ببيان
            موضحا فيه بيان المنتجات محددا تحديدا نافيا للجهالة وسعرها وما سدده
            العميل مقدما من السعر.
          </li>
          <li>
            تلتزم الشركة بإخطار العميل بما يطرأ من تعديل على شبكة مقدمي السلع
            والخدمات طوال فترة سريان العقد سواء عن طريق التطبيق أو أي طريقة
            أخرى.
          </li>
        </ol>

        <h1 class="text-center underline">البند (6) التزامات العميل</h1>
        <ol class="dots">
          <li>
            يلتزم العميل بتسجيل حساب على التطبيق والذي يقوم من خالله بإدارة
            التمويل واألقساط بعد استالم المنتج ويعد هذا االلت ازم جوهريا للشركة
            الستمرار التمويل.
          </li>
          <li>
            يقر العميل بعلمه بأن الشركة غير مسئولة عن المنتج بأي صورة من الصور
            وأن التاجر هو وحده المسؤول عن المنتج بما في ذلك سالمة المنتج وتطابقه
            والشروط القانونية وأي عيوب ظاهرة أو خفية كما يقر العميل بفحصه للمنتج
            فحصا نافيا للجهالة.
          </li>
          <li>
            في حالة عدم سداد العميل لقسطين متتاليين يحق للشركة بتعجيل سداد باقي
            قيمة األقساط وإخطار العميل بذلك كتابة مع عدم اإلخالل بكافة حقوق
            الشركة القانونية واالتفاقية.
          </li>
          <li>
            يكون العميل مسؤول مسئولية تامة عن استخدامه لهاتفه المحمول (بما في
            ذلك شريحة السيم Card Sim) وبريده اإللكتروني وحسابه اإللكتروني على
            التطبيق بما في ذلك على سبيل المثال ال الحصر اسم المستخدم وكلمة السر
            ويلتزم العميل بإبالغ الشركة فور سرقة أو اختراق أو ضياع اسم المستخدم
            وكلمة السر أو الهاتف ا لمحمول أو شريحة السيم (Card Sim) وذلك التخاذ
            الشركة اإلجراءات الالزمة.{' '}
          </li>
          <li>
            يلتزم العميل بعدم رد أو إلغاء عملية الشراء عليه عدم (وبناء إلغاء
            المنتج أو الخدمة) في حالة البدء في سداد أي من أقساط التمويل كما هو
            موضح بالبند الرابع أعاله أو مرور 30 يوما من تاريخ كل معاملة شراء.
          </li>
        </ol>

        <h1 class="text-center underline">البند (7) السرية</h1>
        <ol class="dots">
          <li>
            تلتزم الشركة باحترام السرية لكامل تعامالت العميل، وال يسري هذا
            االلتزام بالسرية على المعلومات التي قد تكون متاحة للغير بصفة عامة،
            أو بفعل العميل، أو مطلوب نشرها، أو اإلفصاح عنها بمقتضى القانون أو
            أمر قضائي أو ألمر حكومي.{' '}
          </li>
          <li>
            يلتزم العميل باإلفصاح للشركة عن بيانات داخلة وأية ضمانات قد تكون
            متطلبة إلبرام عقد التمويل.{' '}
          </li>
          <li>
            من المتفق عليه بين الطرفين أن الشركة تتمتع بصالحيات اإلفصاح عن
            بيانات التمويل وحجمه ومدته إلى شركات االستعالم االئتماني وال يعد ذلك
            إخالالً باحت ارم مبدأ السرية الخاص بالعميل.
          </li>
        </ol>

        <h1 class="text-center underline">البند (8)األحكام العامة</h1>
        <ol class="dots">
          <li>
            يحق للشركة توريق محفظة ديونها لدي الغير أو خصمها أو حوالتها سواء
            بصورة كلية أو أي جزء منها وفقا ألحكام القوانين والقواعد العامة
            للحوالة والحلول وفقا للقانون المدني رقم 131 لسنة 1948 وذلك دون
            معارضة من الطرف الثاني أو الحاجة الستيفاء موافقته.
          </li>
          <li>
            إن إخفاق أو امتناع )سواء بقصد أو عن سهو( الشركة في ممارسة أي من
            حقوقها ال يعتبر تنازالً منها عن ذلك الحق فيما بعد ذلك بعد حيث إن
            التنازل ال يسري إا كتابة.{' '}
          </li>
          <li>
            تسري على هذا العقد أحكام القوانين المعمول بها في جمهورية مصر العربية
            والق ار ارت المنفذة لهذه القوانين.
          </li>
          <li>
            تختص المحاكم المصرية بكافة درجاتها بالفصل في المنازعات الناشئة عن
            تنفيذ أو تفسير هذا العقد وملحقاته.
          </li>
          <li>اإلخطارات:</li>
          <ol class="dots">
            <li>
              العناوين الواردة قرين مسمي كل طرف في صدر هذا العقد موطنا يصح
              قانونا مخاطبته عليه ومن ثم كافة اإلعالنات القضائية والمراسالت
              واإلنذارات واإلخطارات التي تتم عليها وفقا لهذا العقد صحيحة ومنتجة
              آلثارها القانونية ويلتزم كال من طرفي العقد في حالة حدوث أي تغيير
              عليها بإخطار الطرف االخر كتابة.{' '}
            </li>
            <li>
              {' '}
              ومن المعلوم أن أرقام التليفون والفاكس وعناوين البريد اإللكتروني
              والوارد النص عليها في نموذج بيانات العميل المرفق بهذا العقد موثقة
              لدي الشركة ومن ثم يحق للشركة أن ترسل عليها كافة الرسائل التوجيهية
              وإشعارات التنفيذ واإلخطارات التي تتم وفقا لهذا العقد، ويلتزم
              العميل في حالة حدوث أي تغيير عليها بإخطار الشركة كتابة.
            </li>
            <li>
              {' '}
              يجب أن يتم إرسال اإلخطا ارت المتعلقة بهذا العقد كتابة بما في ذلك
              على سبيل المثال بالبريد المسجل أو تسلم باليد مع التوقيع باالستالم
              من الطرف المستلم، أو بالبريد اإللكتروني، أو التطبيق، أو الرسائل
              النصية. يقر العميل بحجية طرق الم ارسالت المنصوص عليها أعاله وبعدم
              الجحد على أي منها.
            </li>
            <li>
              يقر العميل بصحة كافة بيانات للمراسالت المنصوص عليها بالبند األول
              أعاله وفي حالة تعديل عنوان ورقم الهاتف المحمول أو العنوان الخاص
              يتعين على العميل إخطار الشركة فور إجراء إي تعديل وكذلك في حاالت
              فقد العميل الهاتف المحمول أو بيانات البريد اإللكتروني، وال يكون أي
              تغيير فيما سبق ساري في مواجهة الشركة إال بعد إخطار الشركة مسبقا من
              خالل الطرق المنصوص عليها على الشروط واألحكام الخاصة بالتطبيق بما
              في ذلك مركز اتصال خدمة العمالء على رقم هات (
              <span
                className="underlineSolid"
                style={{ minWidth: '100px' }}
              ></span>
              ) .
            </li>
          </ol>
          <li>حرر هذا العقد بين كل من :</li>
        </ol>

        <ul class="signatures">
          <li>(الشركة)</li>
          <li>(العميل)</li>
          <li>االسم:</li>
          <li>االسم:</li>
          <li>الصفة:</li>
          <li>التوقيع:</li>
          <li>التوقيع:</li>
        </ul>
      </div>
    </>
  );
};

export default ContractCredit;
