import axios from './axios.service';

export default class Merchants {
  /**
   * get list of all popular merchants
   * @returns promise
   */
  static getPopular() {
    return axios.get('/api/v2/cmt-backend/merchants/affiliate/popular');
  }

  /**
   * save a list of all popular merhcants
   * @param {Array} popularMerchantsList
   * @returns promise
   */
  static savePopular(popularMerchantsList) {
    return axios.put(
      '/api/v2/cmt-backend/merchants/affiliate/popular',
      popularMerchantsList,
    );
  }

  /**
   * list pagenated merchants
   * @param {Object} filters
   * @returns promise
   */
  static listMerchantsAffiliate(filters) {
    return axios.get('/api/v2/cmt-backend/merchants/affiliate', {
      params: { ...filters },
    });
  }

  /**
   * list all merchants aggregators
   * @returns promise
   */
  static listAffiliateAggregators() {
    return axios.get('/api/v2/cmt-backend/merchants/affiliate/aggregators');
  }

  /**
   * Create a new affliate merchant
   * @param {Object} merchant
   * @returns  promise
   */
  static createMerchantAffiliate(merchant) {
    return axios.post('/api/v2/cmt-backend/merchants/affiliate', merchant);
  }

  /**
   * Update an affilaite merchant
   * @param {Number} id
   * @param {Object} merchant
   * @returns promise
   */
  static updateMerchantAffiliate(id, merchant) {
    return axios.put(`/api/v2/cmt-backend/merchants/affiliate/${id}`, merchant);
  }

  /**
   * Get affilaite merchant info
   * @param {Number} id
   * @param {Boolean} isSourceAdmin
   * @returns promise
   */
  static getMerchantAffiliate(id, isSourceAdmin = false) {
    return axios.get(
      `/api/v2/cmt-backend/merchants/affiliate/${id}?${
        isSourceAdmin ? 'isSourceAdmin=true' : ''
      }`,
    );
  }

  /**
   * Delete affiliate merchant
   * @param {Number} id
   * @returns promise
   */
  static deleteMerchantAffiliate(id) {
    return axios.delete(`/api/v2/cmt-backend/merchants/affiliate/${id}`);
  }

  /**
   * Delete merchant
   * @param {Number} id
   * @returns promise
   */
  static deleteMerchant(id) {
    return axios.delete(`/api/v2/cmt-backend/merchants/delete/${id}`);
  }

  /**
   * Create merchant
   * @param {Object} merchantBody
   * @returns promise
   */
  static createMerchant(body) {
    return axios.post(`/api/v2/cmt-backend/merchants/create`, body);
  }

  /**
   * Update merchant
   * @param {Number} id
   * @param {Object} merchantBody
   * @returns promise
   */
  static updateMerchant(id, body) {
    return axios.post(`/api/v2/cmt-backend/merchants/update/${id}`, body);
  }

  /**
   * Get merchant
   * @param {Number} id
   * @returns promise
   */
  static getMerchant(id) {
    return axios
      .get(`/api/v2/cmt-backend/merchants/get/${id}`)
      .then(res => res.data);
  }

  /**
   * list merchants by filter
   * @param {Object} filters
   * @returns promise
   */
  static listMerchantsByFilter(filters) {
    return axios.get('/api/v2/cmt-backend/merchants/list', {
      params: { ...filters, minOfferCount: 0 },
    });
  }

  /**
   * list merchants by filter
   * @param {Object} filters
   * @returns promise
   */
  static getMerchantCategories() {
    return axios
      .get('/api/v2/cmt-backend/merchants/categories')
      .then(r => r.data);
  }

  /**
   * Triggers a download of csv file
   * @param {Number} affiliateId
   * @returns {Promise} void starts download
   */
  static downloadMerchantTransactions(affiliateId) {
    axios.responseType = 'blob';
    return axios
      .get(
        `/api/v2/cmt-backend/merchants/transactions/orders-csv?affiliateId=${affiliateId}`,
      )
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${affiliateId}.csv`);
        document.body.appendChild(link);
        link.click();
      });
  }

  /**
   * Upload card offers in bulk
   * @param {Binary} file File
   * @returns  promise
   */
  static uploadCATIInBulk(file) {
    const bodyFormData = new FormData();
    bodyFormData.append('file', file);

    return axios.post(
      `/api/v2/cmt-backend/files/bulk-upload?feature=CATIStrategy`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      },
    );
  }
}
